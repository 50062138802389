import React from 'react'
import { Container } from 'react-bootstrap'
import "./index.css"

const index = ({title, subtitle}) => {
  return (       
    <div className='page-title'> 
      <Container>
        <div className='intro'>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
      </Container>
    </div>
   )
}

export default index