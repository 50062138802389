import axios from 'axios';
import {config} from '../config/index'

const estateService = {
    getEstate: async (id) => {
      
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
       const response = await axios.get(`${config.api_path}/estates/${id}`,{
        headers
       });
       return response;
    },
};


export default estateService;
