import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, ListGroup, Badge, Nav, Form, Tab, CardBody, Modal, Table } from 'react-bootstrap';
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import Backbtn from '../../components/backbtn';
import { Activity, AlertCircle, Building2Icon, CheckCircle2, Clock, CreditCard, Receipt, Users, Wallet } from 'lucide-react';
import './index.css'
import { PaymentModal } from '../../components/estatePay/paymentModal';
import { TransactionsTable } from '../../components/estatePay/transactionsTable';
import { PaymentsList } from '../../components/estatePay/paymentList';
import utility, { getFormattedDateForFilename } from "../../helpers/index";
import createPdfInstance from '../../helpers/pdfHelper';
import TooltipWrapper from '../../components/common/tooltip';


const NavigationTabs = () => (
  <Nav className="mb-4">
    <Nav.Item>
      <Button variant="outline-secondary" className="me-2">Select topics</Button>
    </Nav.Item>
    <Nav.Item>
      <Button variant="outline-secondary" className="me-2">Estate Account</Button>
    </Nav.Item>
    <Nav.Item>
      <Button variant="outline-secondary">My Records</Button>
    </Nav.Item>
  </Nav>
);

const PaymentTabs = ({ activeTab, onTabChange }) => {
  const tabs = ['Security Fee', 'Roads Fee', 'Welfare Fee', "Dev't Fee", 'Ads', 'Subscription'];

  return (
    <div className="d-flex flex-wrap mb-4">
      {tabs.map(tab => (
        <button
          key={tab}
          onClick={() => onTabChange(tab)}
          className={`payment-tab ${activeTab === tab ? 'active' : ''}`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};


const PaymentDetails = () => (
  <Card className="mb-4">
    <Card.Body>
      <p className="text-muted mb-4">
        Canaan Estate Malkhvers want to thank you for your contributions towards security fee payments
      </p>

      <Form className="row mb-4">
        <div className="col-md-6 mb-3 mb-md-0">
          <Form.Group>
            <Form.Label className="fw-medium">Select Your Zone</Form.Label>
            <Form.Select>
              <option>Zone B</option>
            </Form.Select>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group>
            <Form.Label className="fw-medium">Select Date</Form.Label>
            <Form.Select>
              <option>Jan, Feb, Mar</option>
            </Form.Select>
          </Form.Group>
        </div>
      </Form>

      <div className="bg-light p-3 rounded">
        <div className="row g-2">
          <div className="col-12">
            <span className="fw-medium">Total Fee:</span> ₦60,000
          </div>
          <div className="col-12">
            <span className="fw-medium">PRN:</span> 22678910
          </div>
          <div className="col-12">
            <span className="fw-medium">Expiry Date:</span> 06-March-2025
          </div>
          <div className="col-12">
            <span className="fw-medium">Verified from:</span> TUVOE Technologies
          </div>
        </div>
      </div>
    </Card.Body>
  </Card>
);

const PaymentMethod = () => {
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <Card className="mb-4">
      <Card.Body>
        <h5 className="fw-medium mb-4">Choose Payment Method</h5>
        <div className="mb-4">
          <p className="text-center fw-medium mb-4">Mobile Money</p>
          <div className="d-flex gap-3 mb-4">
            <Form.Control
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Button>Pay</Button>
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          <p className="text-success mb-0">PRN Generated Successfully from Estate Pay</p>
          <p className="text-success mb-0">Fee generated and transferred to Estate Account</p>
          <p className="text-primary mb-0">Please enter your Private mobile money Pin</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export const paymentItems = [
  {
    id: '1',
    name: 'Monthly Maintenance',
    amount: 150000,
    dueDate: '2025-03-01',
    humanDescription: 'UGX 150,000 for monthly estate maintenance, covering cleaning, landscaping, and general upkeep.',
  },
  {
    id: '2',
    name: 'Security Fee',
    amount: 50000,
    dueDate: '2025-02-28',
    humanDescription: 'UGX 50,000 for security services, contributing to the salary of our estate security personnel.',
  },
  {
    id: '3',
    name: 'Garbage Collection',
    amount: 30000,
    dueDate: '2025-02-28',
    humanDescription: 'UGX 30,000 for garbage collection services to ensure proper waste disposal in the estate.',
  },
  {
    id: '4',
    name: 'Water Bill',
    amount: 80000,
    dueDate: '2025-01-14',
    humanDescription: 'UGX 80,000 for the monthly water bill, covering supply and maintenance of the estate’s water system.',
  },
  {
    id: '6',
    name: 'Special Estate Renovation Fund',
    amount: 250000,
    dueDate: '2025-01-31',
    humanDescription: 'UGX 250,000 contribution towards estate renovations, including repainting common areas and security improvements.',
  },
];


export const transactions = [
  {
    id: '1',
    date: '2024-03-15',
    amount: 150000,
    paymentMethod: 'MTN Mobile Money',
    status: 'Successful',
    description: 'Monthly Maintenance - March 2024',
    prn: 'PRN123456789',
    humanDescription: 'UGX 150,000 for the monthly estate maintenance fee to cover cleaning, landscaping, and general upkeep for March 2024.',
  },
  {
    id: '2',
    date: '2024-03-10',
    amount: 50000,
    paymentMethod: 'Airtel Money',
    status: 'Successful',
    description: 'Security Fee - March 2024',
    prn: 'PRN987654321',
    humanDescription: 'UGX 50,000 for security fee subscription to contribute to the salary of our estate security personnel for March 2024.',
  },
  {
    id: '3',
    date: '2024-03-05',
    amount: 30000,
    paymentMethod: 'Bank Transfer',
    status: 'Pending',
    description: 'Garbage Collection - March 2024',
    prn: 'PRN456789123',
    humanDescription: 'UGX 30,000 for garbage collection services to ensure proper waste management in the estate for March 2024.',
  },
  {
    id: '4',
    date: '2024-02-28',
    amount: 150000,
    paymentMethod: 'MTN Mobile Money',
    status: 'Successful',
    description: 'Monthly Maintenance - February 2024',
    prn: 'PRN789123456',
    humanDescription: 'UGX 150,000 for the monthly estate maintenance fee to cover cleaning, landscaping, and general upkeep for February 2024.',
  },
  {
    id: '5',
    date: '2024-02-25',
    amount: 50000,
    paymentMethod: 'Airtel Money',
    status: 'Successful',
    description: 'Security Fee - February 2024',
    prn: 'PRN321654987',
    humanDescription: 'UGX 50,000 for security fee subscription to contribute to the salary of our estate security personnel for February 2024.',
  },
  {
    id: '6',
    date: '2024-02-20',
    amount: 30000,
    paymentMethod: 'Bank Transfer',
    status: 'Successful',
    description: 'Garbage Collection - February 2024',
    prn: 'PRN654987321',
    humanDescription: 'UGX 30,000 for garbage collection services to ensure proper waste management in the estate for February 2024.',
  },
  {
    id: '7',
    date: '2024-01-15',
    amount: 150000,
    paymentMethod: 'MTN Mobile Money',
    status: 'Successful',
    description: 'Monthly Maintenance - January 2024',
    prn: 'PRN111222333',
    humanDescription: 'UGX 150,000 for the monthly estate maintenance fee to cover cleaning, landscaping, and general upkeep for January 2024.',
  },
  {
    id: '8',
    date: '2024-01-10',
    amount: 50000,
    paymentMethod: 'Airtel Money',
    status: 'Successful',
    description: 'Security Fee - January 2024',
    prn: 'PRN444555666',
    humanDescription: 'UGX 50,000 for security fee subscription to contribute to the salary of our estate security personnel for January 2024.',
  },
  {
    id: '9',
    date: '2024-01-05',
    amount: 30000,
    paymentMethod: 'Bank Transfer',
    status: 'Pending',
    description: 'Garbage Collection - January 2024',
    prn: 'PRN777888999',
    humanDescription: 'UGX 30,000 for garbage collection services to ensure proper waste management in the estate for January 2024.',
  },
  {
    id: '10',
    date: '2023-12-20',
    amount: 250000,
    paymentMethod: 'MTN Mobile Money',
    status: 'Successful',
    description: 'Special Estate Renovation Fund',
    prn: 'PRN000111222',
    humanDescription: 'UGX 250,000 contribution towards estate renovations, including repainting common areas and improving security infrastructure.',
  },
];


export const DownloadModal = ({ transactions, onClose, onDownloadCSV, onDownloadPDF }) => {
  const totalAmount = transactions.reduce((sum, t) => sum + t.amount, 0);


  return (
    <Modal size='lg' show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between discount text-bold">
          <p>Selected Transactions: {transactions.length}</p>
          <p>Total Amount: UGX {totalAmount.toLocaleString()}</p>
        </div>

        <div className="table-responsive">
          <Table hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>PRN</th>
                <th>Status</th>
                <th className="text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{transaction.date}</td>
                  <td>{transaction.description}</td>
                  <td>{transaction.prn}</td>
                  <td>
                    <Badge
                      className={`status-badge ${transaction.status.toLowerCase()}`}
                    >
                      {transaction.status}
                    </Badge>
                  </td>
                  <td className="text-end">UGX {transaction.amount.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <th colSpan={4} className='text-end mr-2'>Total Amount: </th>
              <th className="text-end">UGX {totalAmount.toLocaleString()}</th>
            </tfoot>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onDownloadCSV}>
          Download CSV
        </Button>
        <Button variant="primary" onClick={onDownloadPDF}>
          Download PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EstatePay = () => {


  const [activeTab, setActiveTab] = useState('payments');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    prn: '',
    amount: '',
    date: ''
  });
  const [username, setUsername] = useState('');

  useEffect(() => {
    const user = localStorage.getItem("user_name");
    if (user) {
      setUsername(user);
    }
  }, [])


  const itemsPerPage = 5;
  const totalPages = Math.ceil(transactions.length / itemsPerPage);
  const totalDue = paymentItems.reduce((sum, item) => sum + item.amount, 0);
  const totalPaid = transactions.reduce((sum, transaction) => sum + transaction.amount, 0);

  // Filter transactions based on filter criteria
  const filteredTransactions = transactions.filter(transaction => {
    const prnMatch = transaction.prn.toLowerCase().includes(filters.prn.toLowerCase());
    const amountMatch = transaction.amount.toString().includes(filters.amount);
    const dateMatch = transaction.date.includes(filters.date);
    return prnMatch && amountMatch && dateMatch;
  });


  const handleShareEmail = (transaction) => {


    const subject = "My Transaction Summary";
    const body = `${utility.capitalizeWords(username ?? 'Estate Member')} has paid UGX ${transaction.amount.toLocaleString()} for ${transaction.description} on ${transaction.date}, PRN: ${transaction.prn}`
      + `\n\nTo view more details, click here: ${window.location.href}\n\nPay your Estate Transactions with tuwe.`;

    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleShareWhatsApp = (transaction) => {

    const message = `${utility.capitalizeWords(username ?? 'Estate Member')} has paid UGX ${transaction.amount.toLocaleString()} for ${transaction.description} on ${transaction.date}, PRN: ${transaction.prn}`
      + `\n\nTo view more details, click here: ${window.location.href}\n\nPay your Estate Transactions with tuwe.`;

    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`);
  };

  const handleDownloadCSV = () => {
    const selected = transactions.filter(t => selectedTransactions.includes(t.id));
    const csvContent = [
      ['Date', 'Description', 'Payment Method', 'PRN', 'Status', 'Amount'],
      ...selected.map((transaction) => [
        transaction.date,
        transaction.description,
        transaction.paymentMethod,
        transaction.prn,
        transaction.status,
        transaction.amount,
      ]),
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `transaction-history-${getFormattedDateForFilename()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadPDF = () => {
    const selected = transactions.filter(t => selectedTransactions.includes(t.id));
    const totalAmount = selected.reduce((sum, t) => sum + t.amount, 0);
    const { doc, autoTable, savePdf, startY } = createPdfInstance();

    const titleY = startY + 10;

    doc.setFontSize(18);
    doc.text(`Transaction Summary for ${utility.capitalizeWords(username ?? 'Estate Member')}`, 14, titleY);
    doc.line(15, titleY + 3, 180, titleY + 3);

    // Add table dynamically after the title
    autoTable({
      head: [['Date', 'Description', 'Payment Method', 'PRN', 'Status', 'Amount (UGX)']],
      body: selected.map(t => [
        t.date,
        t.description,
        t.paymentMethod,
        t.prn,
        t.status,
        `${t.amount.toLocaleString()}`
      ]),
      startY: titleY + 10,
      styles: { fontSize: 10 },
      columnStyles: {
        5: { halign: 'right' },
      },
      foot: [
        [{ colSpan: 5, content: 'Total Amount:', styles: { halign: 'right' } },
        `UGX ${totalAmount.toLocaleString()}`]
      ]
    });

    savePdf(`transaction-history-${getFormattedDateForFilename()}.pdf`);
  };


  return (
    <Layout>
      <PageIntro
        title="Estate Pay"
        description="Stay updated with the latest announcements and notifications."
        subtitle="Simplify your estate payments"
      />
      <Container className="mt-4">
        <Backbtn />
        {/* <NavigationTabs />
        <PaymentTabs activeTab={activeTab} onTabChange={setActiveTab} />
        <PaymentDetails />
        <PaymentMethod /> */}


        <Row className="g-4">

          <Col lg={12}>
            <Row className="g-4 mb-4">
              <Col md={6}>
                <Card className="payment-card h-100">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="mb-0">Total Due</h5>
                      <Wallet size={24} className="text-danger" />
                    </div>
                    <h3 className="text-danger">UGX {totalDue.toLocaleString()}</h3>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                    <small className="text-muted">Due by March 31, 2025</small>
                      <Button variant="outline-primary" onClick={() => setActiveTab("payments")}>View details</Button>
                    </div>
                    
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="payment-card h-100">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="mb-0">Total Paid</h5>
                      <Receipt size={24} style={{ color: '#833c99' }} />

                    </div>
                    <h3 style={{ color: '#833c99' }}>UGX {totalPaid.toLocaleString()}</h3>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <small className="text-muted">Last payment: Nov 15, 2024</small>
                      <Button variant="outline-primary" onClick={() => setActiveTab("transactions")}>View details</Button>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
            </Row>

          </Col>


        </Row>
        <Row className='g-4'>
          <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k || 'payments')}>
            <Nav variant="tabs" className="mb-4">
              <Nav.Item>
                <Nav.Link eventKey="payments">Payments</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="transactions">Transactions</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="payments">
                <Card>
                  <Card.Header className="bg-primary text-white">
                    <h5 className="mb-0 d-flex align-items-center">
                      <CreditCard className="me-2" size={20} />
                      Pending Payments
                    </h5>
                  </Card.Header>
                  <CardBody>
                    <PaymentsList
                      items={paymentItems}
                      onPaymentClick={(item) => {
                        setSelectedItem(item);
                        setIsModalOpen(true);
                      }}
                    />
                  </CardBody>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="transactions">
                <Card>
                  <Card.Header className="bg-primary text-white">
                    <h5 className="mb-0 d-flex align-items-center">
                      <Activity className="me-2" size={20} />
                      Transaction History
                    </h5>
                  </Card.Header>
                  <CardBody>
                    <TransactionsTable
                      transactions={filteredTransactions}
                      currentPage={currentPage}
                      totalPages={Math.ceil(filteredTransactions.length / itemsPerPage)}
                      onPageChange={setCurrentPage}
                      itemsPerPage={itemsPerPage}
                      selectedTransactions={selectedTransactions}
                      onSelectTransactions={setSelectedTransactions}
                      filters={filters}
                      onFilterChange={(type, value) => setFilters(prev => ({ ...prev, [type]: value }))}
                      onDownload={() => setIsDownloadModalOpen(true)}
                      onShareWhatsApp={handleShareWhatsApp}
                      onShareEmail={handleShareEmail}
                    />
                  </CardBody>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Row>
      </Container>

      {isDownloadModalOpen && (
        <DownloadModal
          transactions={transactions.filter(t => selectedTransactions.includes(t.id))}
          onClose={() => setIsDownloadModalOpen(false)}
          // onDownloadCSV={() => {console.log("Downloading CSV")}}
          onDownloadCSV={handleDownloadCSV}
          onDownloadPDF={handleDownloadPDF}
        />
      )}

      {selectedItem && (
        <PaymentModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedItem(null);
          }}
          itemName={selectedItem.name}
          amount={selectedItem.amount}
        />
      )}
    </Layout>
  );
};

export default EstatePay;