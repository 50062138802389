import {React, useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./login.css"
import authService from '../../services/authService'
import SideAdvert from '../../components/sideAdvert'
import { toast } from 'react-toastify'
const ForgotPass = () => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);  // Show loading spinner
    try {
      const user = await authService.forgotpass(email);
      toast.success(user)
      navigate('/dashboard'); // Redirect to the dashboard
    } catch (error) {
      console.log(error.code);
      if (error.code === "ERR_NETWORK") {
          setError('Error, check your network');
          toast.error(error.message)
      } else {
        setError('In valid email or password');
        toast.error(error.message)
      }
    } finally {
      setLoading(false);  // Hide loading spinner
    }
  };

  useEffect(() => {
    document.title = "Tuwe | Forgot Password";
   })
  return (
    <section className='login'>
      <SideAdvert />
      <section className='login-area col-md-7'>
          <form className='login-form col-md-7' onSubmit={handleSubmit}>
              <h4>Forgot Password</h4> 
              <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Email Address<span>*</span></label>
                    <input className='form-control'
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
              </div>
              <div className='form-row'>
              <button type='submit' className='btn col-md-12 btn-primary' disabled={loading}>
                {loading ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="0.5em" height="0.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" dur="0.6s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
                ) : (
                  <span>Submit</span>
                )}
              </button>
              </div>
              <div className='form-row'>
                <div className='col-md-12 forgot'>
                  <Link to="/login"><span>Login</span></Link>
                  <Link to="/register"><span>Register</span></Link>
                </div>
            </div>
            </form>
      </section>
    </section>
  )
}
export default ForgotPass