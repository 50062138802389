import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import LocationIcon from '../../assets/svg/location.svg';
import Modal from "../../components/modal";
import Layout from '../../layout/index';
import PageIntro from '../../components/pageIntro'
import serviceProviderService from '../../services/serviceProviderService';

const Index = () => {
    const types = ["Emergency", "Essential", "Desirable"];
  const [activeType, setActiveType] = useState(0); 
  const [activeCategory, setActiveCategory] = useState(0); 
  const [activeSubCategory, setActiveSubCategory] = useState(0); 
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [scrollPosition1, setScrollPosition1] = useState(0);
  const [scrollPosition2, setScrollPosition2] = useState(0);


  // Amount to scroll each time
  const scrollAmount = 200;
  const scrollLeft1 = () => {
    setScrollPosition1((prev) => prev + scrollAmount);
  };

  const scrollRight1 = () => {
    setScrollPosition1((prev) => prev - scrollAmount);
  };

  const scrollLeft2 = () => {
    setScrollPosition2((prev) => prev + scrollAmount);
  };

  const scrollRight2 = () => {
    setScrollPosition2((prev) => prev - scrollAmount);
  };
  const handleClose = () => {
    setShowModalIndex(null); 
  };

  const handleShow = (index) => {
    setShowModalIndex(index); 
  };

  const handleTypeClick = (index) => {
    setActiveType(index);
    setActiveCategory(0);
    setActiveSubCategory(0); 
    setServices([]); 

    if (index === 0) getEssentials();
    else if (index === 1) getRelevant();
    else if (index === 2) getDesirable();
  };

  const handleCategoryClick = (id) => {
    setActiveCategory(id);
    setSubCategories(categories.filter(item => id === item.id)[0].service_sub_categories.data);
    setActiveSubCategory(0); 
    setServices([]); 
  };

  const handleSubCategoryClick = (id) => {
    setActiveSubCategory(id);
  };

  const getEssentials = async () => {
    try {
      const resp = await serviceProviderService.getEssentialCategories();
      setCategories(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const getRelevant = async () => {
    try {
      const resp = await serviceProviderService.getRelevantCategories();
      setCategories(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const getDesirable = async () => {
    try {
      const resp = await serviceProviderService.getDesireableCategories();
      setCategories(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const getServices = async () => {
    try {
      const resp = await serviceProviderService.getServiceProviders(activeCategory);
      setServices(resp.sub_categories.filter(item => item.id === activeSubCategory)[0].service_providers);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getEssentials();
  }, []);

  useEffect(() => {
    getServices();
  }, [activeSubCategory]);

  return (
    <Layout>
        <PageIntro title="Service Provider" subtitle="your verified service providers" />
        <section className="service-provider" style={{paddingTop:"40px"}}>
        <Container>
          <Row>
            <ul className="services-type">
              {types.map((type, index) => (
                <li
                  key={index}
                  className={activeType === index ? 'active' : ''}
                  onClick={() => handleTypeClick(index)}
                >
                  {type} Service
                </li>
              ))}
            </ul>
          </Row>
          <Row className="centralise">
          <button type='button' className='btn-arrow col-md-1' 
           onClick={scrollLeft1}>
            <svg
              x="0px"
              y="0px"
              className='left-arrow'
              width="20px"
              height="20px"
              viewBox="0 0 960 560"
              enableBackground="new 0 0 960 560"
            >
              <g id="Rounded_Rectangle_33_copy_4_1_">
                <path
                  fill="#fff"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </g>
            </svg>
          </button>
            <div className="service-scroll">
              <ul className="service-list">
                {categories.map((category, index) => (
                  <li
                    key={index}
                    className={activeCategory === category.id ? 'active' : ''}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>
            <button type='button' className='btn-arrow col-md-1' onClick={scrollRight1}>
            <svg
              x="0px"
              y="0px"
              className='right-arrow'
              width="20px"
              height="20px"
              viewBox="0 0 960 560"
              enableBackground="new 0 0 960 560"
            >
              <g id="Rounded_Rectangle_33_copy_4_1_">
                <path
                  fill="#fff"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </g>
            </svg>
          </button>
          </Row>

          {/* Subcategory Navigation */}
          <Row className="centralise">
          <button type='button' className='btn-arrow col-md-1' 
           onClick={scrollLeft1}>
            <svg
              x="0px"
              y="0px"
              className='left-arrow'
              width="20px"
              height="20px"
              viewBox="0 0 960 560"
              enableBackground="new 0 0 960 560"
            >
              <g id="Rounded_Rectangle_33_copy_4_1_">
                <path
                  fill="#fff"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </g>
            </svg>
          </button>
            <div className="stage-scroll col-md-11">
              <ul className="stage-list">
                {subCategories.map((subcategory, index) => (
                  <li
                    key={index}
                    className={activeSubCategory === subcategory.id ? 'active' : ''}
                    onClick={() => handleSubCategoryClick(subcategory.id)}
                  >
                    {subcategory.name}
                  </li>
                ))}
              </ul>
            </div>
            <button type='button' className='btn-arrow col-md-1' onClick={scrollRight1}>
            <svg
              x="0px"
              y="0px"
              className='right-arrow'
              width="20px"
              height="20px"
              viewBox="0 0 960 560"
              enableBackground="new 0 0 960 560"
            >
              <g id="Rounded_Rectangle_33_copy_4_1_">
                <path
                  fill="#fff"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </g>
            </svg>
          </button>
          </Row>

          {/* Service List */}
          <Row className="centralise">
            {services.map((service, index) => (
              <React.Fragment key={index}>
                {/* Modal */}
                {showModalIndex === index && (
                  <Modal
                    show={showModalIndex !== null}
                    title={service.business_name}
                    content={service.description}
                    handleClose={handleClose}
                  />
                )}

                <div className="col-md-3 card-boda">
                  <div className="img-section" style={{ height: "200px", backgroundColor: '#ccc' }}>
                    <img src={"./images/marketFour.jpg"} width="100%" height="100%" alt="" />
                  </div>
                  <h5>{service.business_name}</h5>
                  <div className="details-section">
                    <span className="img-sec">
                      <img src={LocationIcon} alt="icon" />
                    </span>
                    <span className="loc-det">{service.district.name}, {service.subcounty.name}</span>
                  </div>
                  <h6>Reach us on</h6>
                  <p align="center">{service.telephone_number} <br/> {service.telephone_number}</p>
                  <Row className="centralise">
                    <button
                      style={{ height: "35px" }}
                      onClick={() => handleShow(index)}
                      className="col-md-3 btn btn-primary"
                    >
                      Details
                    </button>
                  </Row>
                </div>
              </React.Fragment>
            ))}
          </Row>
        </Container>
      </section>

    </Layout>
  )
}

export default Index