
import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import brokerService from '../../services/brokerService';
import LandItem from '../../components/landItem'
import './index.css';
import verifiedImg from '../../assets/images/verified-gold.fw.png'
import Layout from '../../layout/index';
import PageIntro from '../../components/pageIntro';
import MapContainer from '../../components/map';
import { useParams } from 'react-router-dom';
import PropertyItem from '../../components/propertyItem'
import { Link } from 'react-router-dom';
import Backbtn from '../../components/backbtn';



const LandDetails = () => {
  const {id, type} =useParams()
  // State for the scroll position
  const [properties, setProperties] =useState([])
  const [scrollPosition, setScrollPosition] = useState(0);
  const [agencies, setAgencies] = useState([]); // Agencies list
  const [activeType, setActiveType] = useState(0); // Default active service
  const [activeAgency, setActiveAgency] = useState(0); // Default active agency

  // Fetch agencies from the backend
  const getAgency = async () => {
    try {
      const resp = await brokerService.getAgencies();
      setAgencies(resp);
    } catch (error) {
      console.log('Error fetching agencies:', error);
    }
  };

  // Fetch all land listings
  const getAllLandListings = async () => {
    try {
      const resp = await brokerService.getAllLandProperties();
      setProperties(resp)
    } catch (error) {
      console.log('Error fetching all land properties:', error);
    }
  };

  // Fetch all property listings
  const getAllPropertyListings = async () => {
    try {
      const resp = await brokerService.getAllHouseProperties();
      setProperties(resp)
    } catch (error) {
      console.log('Error fetching all property listings:', error);
    }
  };

  // Fetch agency land listings
  const getAgencyLandListings = async (id) => {
    try {
      const resp = await brokerService.getAgencyLandProperties(id);
      setProperties(resp)
    } catch (error) {
      console.log('Error fetching agency land properties:', error);
    }
  };

  // Fetch agency property listings
  const getAgencyPropertyListings = async (id) => {
    try {
      const resp = await brokerService.getAgencyHouseProperties(id);
      setProperties(resp)
    } catch (error) {
      console.log('Error fetching agency property listings:', error);
    }
  };

  // Handle type selection (Land/Property/Bidding)
  const handleTypeClick = (index) => {
    setActiveType(index);
    if (index === 0) {
      getAllLandListings();
    } else if (index === 1) {
      getAllPropertyListings();
    }
  };

  // Handle agency selection
  const handleAgencyClick = (id) => {
    setActiveAgency(id);
    if (id === 0) {
      if (activeType === 0) {
        getAllLandListings(); // All properties if 'All' is selected
      } else {
        getAllPropertyListings();
      }
    } else {
      // Fetch agency-specific listings
      if (activeType === 0) {
        getAgencyLandListings(id);
      } else if (activeType === 1) {
        getAgencyPropertyListings(id);
      }
    }
  };

  useEffect(() => {
    document.title = "Tuwe | Digital Broker";
    getAgency();
    if (id == 1) {
      getAllLandListings();
      console.log('Effect land listing');
    } else if (id == 2) {
      getAllPropertyListings();
      console.log('Effect property listing');
    }
  }, [activeType]); // Effect reruns when activeType changes

  const getSubtitle=()=>{
    return (id ==1 && type ==1)?
        "Your Verified Land for Sale":
             (id ==1 && type ==2)?"Your Verified Land for Rent":
                 (id ==2 && type ==1)?"Your Verified Houses for Sale":
                 (id ==2 && type ==2)?"Your Verified Houses for Rent":
                     ""
  }

  return (
    <Layout>
      <PageIntro title="Digital Broker" subtitle= "Land For Sale "/>
      <Container>
        <br />
      <Backbtn />

        <Row>
          <aside className="sidebar col-md-3">
            <h4>Filter</h4>
            <div className="filter-type">
              <label>By Status</label>
              <select name="status" type="text">
                <option value="rent">Rental</option>
                <option value="sale">On Sale</option>
                <option value="sold">Sold</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Type</label>
              <select name="type" type="text" onChange={(e) => handleTypeClick(Number(e.target.value))}>
                <option value="0">Land</option>
                <option value="1">Property</option>
                <option value="2">Bidding</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Agency</label>
              <select name="agency" type="text" onChange={(e) => handleAgencyClick(Number(e.target.value))}>
                <option value="0">All Agencies</option>
                {agencies.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-type">
              <label>By Category</label>
              <select name="category" type="text">
                <option value="mailo">Mailo Land</option>
                <option value="kabaka">Kabaka Land</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Price</label>
              <div className="price-section">
                <input type="text" name="min-price" style={{ width: '45%' }} placeholder="Min" />
                <input type="text" name="max-price" style={{ width: '45%' }} placeholder="Max" />
              </div>
            </div>
            <div className="filter-type">
              <label>By District</label>
              <select name="district" type="text">
                <option value="kampala">Kampala</option>
                <option value="wakiso">Wakiso</option>
                <option value="entebbe">Entebbe</option>
                <option value="mukono">Mukono</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Constituency</label>
              <select name="constituency" type="text">
                <option value="kira">Kira</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Parish</label>
              <select name="parish" type="text">
                <option value="kito">Kito</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Village</label>
              <select name="village" type="text">
                <option value="kito">Kito</option>
              </select>
            </div>
          </aside>
          <div className="col-md-9 bodas" style={{ backgroundColor: '#ccc', padding: '0', marginLeft: '20px', marginTop: '30px' }}>
            <MapContainer properties={properties} />
          </div>
          </Row>
          <Row> <div className='verified centralise'>
            <Link to="/verified">
            <img src={verifiedImg} alt="verified" height="100px" />
            </Link>
            <h4 style={{marginTop:"30px", marginLeft:"20px"}}>{getSubtitle()}</h4>
          </div>
             <Row className='centralise'>
            
                      <button type="button" className="btn-arrow col-md-1" >
                        <svg x="0px" y="0px" className="left-arrow" width="20px" height="20px" viewBox="0 0 960 560" enableBackground="new 0 0 960 560">
                          <g id="Rounded_Rectangle_33_copy_4_1_">
                            <path fill="#fff" d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"></path>
                          </g>
                        </svg>
                      </button>
                      <div className="stage-scroll col-md-7" style={{display:"flex"}}>
                        <ul className="stage-list" style={{ transform: `translateX(-${scrollPosition}px)`, transition: 'transform 0.3s ease'}}>
                          <li className="active" onClick={() => handleAgencyClick(0)}>All</li>
                          {agencies.map((agency, index) => (
                            <li
                              key={index}
                              className={activeAgency === agency.id ? 'active' : ''}
                              onClick={() => handleAgencyClick(agency.id)}
                            >
                              {agency.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button type="button" className="btn-arrow col-md-1">
                        <svg x="0px" y="0px" className="right-arrow" width="20px" height="20px" viewBox="0 0 960 560" enableBackground="new 0 0 960 560">
                          <g id="Rounded_Rectangle_33_copy_4_1_">
                            <path fill="#fff" d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"></path>
                          </g>
                        </svg>
                      </button>
                    </Row>
                    <Row>
                    
                       <div className="col-md-12 bodas">
                       { (id ==1)?(
                        <>
                         <LandItem />
                          <LandItem />
                          <LandItem />
                          <LandItem />
                          <LandItem />
                          <LandItem />
                        </>
                        ):(
                          <>
                           <PropertyItem />
                          <PropertyItem />
                          <PropertyItem />
                          <PropertyItem />
                          <PropertyItem />
                          <PropertyItem />
                          </>
                        )  }       
                          </div>
                    </Row>
           
          </Row>
         
      </Container>
    </Layout>
  );
};

export default LandDetails;
