import {React, useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./login.css"
import authService from '../../services/authService'
import SideAdvert from '../../components/sideAdvert'
import { toast } from 'react-toastify'
import Backbtn from '../../components/backbtn';


const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);  // Show loading spinner
    try {
      const response = await authService.login(email, password);
      toast.success(`Welcome ${response?.user?.name?.toUpperCase()}`)
      navigate('/'); // Redirect to the dashboard
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        setError('Error, check your network');
        toast.error(error.response.data.message)
      }else if(error.code === "ERR_BAD_REQUEST"){
        setError('Invalid email or password');
        toast.error(error.response.data.message)
      }else {
        setError('Invalid email or password');
        toast.error(error?.response?.data?.message || error?.message)
      }
    } finally {
      setLoading(false);  // Hide loading spinner
    }
  };

  useEffect(() => {
    document.title = "Tuwe | Login";
   })
  return (
    <section className='login'>
      <SideAdvert />
      <section className='login-area col-sm-12 col-lg-7 col-md-6'>
      <Backbtn />

          <form className='login-form col-md-7' onSubmit={handleSubmit}>
              <h4>Account Login</h4> 
                
              <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Email Address<span>*</span></label>
                    <input className='form-control'
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
              </div>
              <div className="form-row">
                  <div className="form-group col-md-12">
                  <label>Password<span>*</span></label>
                      <input className='form-control'
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                <span
                  className={showPassword ? 'eye-open' : 'eye-closed'}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <svg className="eye-icon" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M12 5C7.824 5 4.36 7.295 3 10.5 4.36 13.705 7.824 16 12 16c4.176 0 7.64-2.295 9-5.5C19.64 7.295 16.176 5 12 5zm0 2c2.465 0 4.526 1.352 5.615 3.33C16.525 12.648 14.465 14 12 14c-2.465 0-4.525-1.352-5.615-3.33C7.475 8.352 9.535 7 12 7zM12 9a2 2 0 0 0 0 4 2 2 0 0 0 0-4z" />
                    </svg>
                  ) : (
                    <svg className="eye-icon" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M7.119 14.563L5.982 16.53l-1.732-1 1.301-2.253A8.97 8.97 0 0 1 3 7h2a7 7 0 0 0 14 0h2a8.973 8.973 0 0 1-2.72 6.448l1.202 2.083-1.732 1-1.065-1.845A8.944 8.944 0 0 1 13 15.946V18h-2v-2.055a8.946 8.946 0 0 1-3.881-1.382z" />
                    </svg>
                  )}
                </span>
                </div>
              </div>
              <div className='form-row'>
              <button type='submit' className='btn col-md-12 btn-primary' disabled={loading}>
                {loading ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="0.5em" height="0.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" dur="0.6s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
                ) : (
                  <span>Login</span>
                )}
              </button>
              </div>
              <div className='form-row'>
                <div className='col-md-12 forgot'>
                  <Link to="/forgot-password"><span>Forgot Password?</span></Link>
                  <Link to="/register"><span>Register</span></Link>
                </div>
            </div>
              
            </form>
            
      </section>
    </section>
  )
}

export default Login