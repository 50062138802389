import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes'

function App() {
  return (
    <>
     <Router>
        <AppRoutes />
        <ToastContainer />
     </Router>
     
    </>
  );
}

export default App;
