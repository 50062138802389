import {React, useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./login.css"
import authService from '../../services/authService'
import SideAdvert from '../../components/sideAdvert'
import { toast } from 'react-toastify'
const EmailVerification = () => {
   
  const queryParameters = new URLSearchParams(window.location.search)

  const [formData, setFormData] = useState({
    id:queryParameters.get("id"),
    hash:queryParameters.get("hash"),
    expires:queryParameters.get("expies"),
    signature:queryParameters.get("signature")
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleVerification = async (event) => {
    try {
      const user = await authService.verifyEmail(formData);
      console.log(user);
      toast.success("email verified successfully")
    } catch (error) {
      console.log(error.code);
      if (error.code === "ERR_NETWORK") {
          setError('Error, check your network');
      } else {
        setError('In valid email or password');
      }
    } finally {
      setLoading(false);  // Hide loading spinner
    }
  };

  useEffect(() => {
    document.title = "Tuwe | verify email";
    handleVerification();
   })
  return (
    <section className='login'>
      <SideAdvert />
      <section className='login-area col-md-7'>
          <form className='login-form col-md-7' >
              <h4>Email Verified</h4> 
                 
                <div className='form-row' style={{display:"flex",justifyContent:"center"}}>
                <Link to="/login"><button type='button' className='btn btn-primary'>Login</button></Link>
            </div>
            </form>
      </section>
    </section>
  )
}
export default EmailVerification