import React from 'react'
import Rotator from '../../components/rotator'
// import BodaStages from '../../components/bodaStages'
// import ServiceProvider from '../../components/serviceProvider'
// import DigitalBroker from '../../components/digitalBroker/index'
// import Header from '../../templates/Header';
import Header from '../../templates/Header2';
import Footer from '../../templates/Footer';
import BodaStages from '../../components/bodaStages/bodaStage';
import ServiceProviders from '../../components/serviceProvider/serviceProvider';
import DigitalBroker from '../../components/digitalBroker/digitalBroker';

const Index = () => {
  return (
    <>
     <Header />
        <Rotator />
        <main>
          <BodaStages />
          <ServiceProviders />
          <DigitalBroker />
        </main>
      <Footer />
    </>
  )
}
export default Index