import React, { useState } from "react";
import { Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube,} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { config } from "../config";
import './header.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    title: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    try {
      const response = await axios.post(`${config.api_path}/contact`, formData);
      setStatus(response.data.message);
      setFormData({ fullname: "", email: "", title: "", message: "" });
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      setStatus("Failed to send message. Try again.");
    }
  };

  return (
    <footer>
      <Container>
        <Row>
          <div className="col-lg-4 col-md-6 ">
            <h4>Tuwe Uganda</h4>
            <p>
              “Our Promise” At Tuwe Technologies, we don’t just deliver
              solutions, we create opportunities.
            </p>
            <p>
              Whether you’re a property buyer looking for security, an estate
              manager seeking efficiency, or a residential estate community
              member in need of trusted services, Tuwe is your partner for
              progress.
            </p>
            <strong>Reach us today and experience the transformation</strong>
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="widget mb-5 mb-lg-0">
                  <h4 className="text-capitalize">Quick Links</h4>

                  <ul className="list-unstyled footer-menu lh-35">
                    <li><Link to="/terms-of-service#terms-and-conditions">Terms &amp; Conditions</Link></li>
                    <li><Link to="/terms-of-service#community-guidelines">Community guidelines</Link></li>
                    <li><Link to="/terms-of-service#privacy-policy">Privacy Policy</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <h4>Contact Us</h4>
            <ul>
              <li>
                <span>Head Office Address: </span>Uganda, Kampala, Parliament
                Road.
              </li>
              <li>
                <span>Building: </span>Bhatia Chambers, 2nd Floor, Room No.3.
              </li>
              <li>
                <span>Box Number: </span>P.O. Box 171496, Kampala, Uganda.
              </li>
              <li>
                <span>Phone: </span>
                <a
                  href="tel:+256708461780"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  +256 708 461 780
                </a>{" "}
                /
                <a
                  href="https://wa.me/+256772410671"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  +256 772 410 671
                </a>{" "}
                (WhatsApp enabled).
              </li>
              <li>
                <span>Email:</span>{" "}
                <a
                  href="mailto:info@tuweug.com"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  info@tuweug.com
                </a>
                /{" "}
                <a
                  href="mailto:support@tuweug.com"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  support@tuweug.com.
                </a>{" "}
              </li>
            </ul>

           
            <h4>Follow Us</h4>

            <div className="social-icons mt-2">
            <a
              href="https://www.facebook.com/TuweUganda/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                size="2x"
                style={{ margin: "0 10px", color: "white" }}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCvWaF4sTgBbqO0U4U0VR60g"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                size="2x"
                style={{ margin: "0 10px", color: "white" }}
              />
            </a>
            <a
              href="https://www.instagram.com/tuweug/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="2x"
                style={{ margin: "0 10px", color: "white" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/tuwe-uganda/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                size="2x"
                style={{ margin: "0 10px", color: "white" }}
              />
            </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <h4>Message Us</h4>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Full Name:</label>
                <input
                  className="form-control"
                  name="fullname"
                  type="text"
                  value={formData.fullname}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Email Address:</label>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Title:</label>
                <input
                  className="form-control"
                  name="title"
                  type="text"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Message:</label>
                <textarea
                  className="form-control"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="mt-2 btn btn-outline-primary">
                Submit
              </button>
            </form>

            {status && <p>{status}</p>}
          </div>
        </Row>
        <Row>
          <div className="base-footer">
            © {currentYear} tuwe.com. All Rights Reserved
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
