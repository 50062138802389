import axios from 'axios';
import {config} from '../config/index'

const serviceProviderService = {
  getEssentialCategories: async () => {
    const storedToken = localStorage.getItem("access_token");
    const headers = storedToken
      ? { Authorization: `Bearer ${storedToken}` }
      : {};
    const response = await axios.get(
      `${config.api_path}/service-categories/essential`,
      { headers }
    );
    const { data } = response.data;
    return data;
  },
  getDesireableCategories: async () => {
    const storedToken = localStorage.getItem("access_token");
    const headers = storedToken
      ? { Authorization: `Bearer ${storedToken}` }
      : {};
    const response = await axios.get(
      `${config.api_path}/service-categories/desirable`,
      { headers }
    );
    const { data } = response.data;
    return data;
  },
  getRelevantCategories: async () => {
    const storedToken = localStorage.getItem("access_token");
    const headers = storedToken
      ? { Authorization: `Bearer ${storedToken}` }
      : {};
    const response = await axios.get(
      `${config.api_path}/service-categories/relevant`,
      { headers }
    );
    const { data } = response.data;
    return data;
  },

  getServiceProviders: async (id, params = {}) => {
    const storedToken = localStorage.getItem("access_token");
    const estate = localStorage.getItem("estate"); // Get estate from local storage

    const headers = storedToken
      ? { Authorization: `Bearer ${storedToken}` }
      : {};
    
    if (estate) {
      params.estate_id = estate;
    } else {
      params.visibility = "Public";
    }


    const queryString = new URLSearchParams(params).toString();

    const response = await axios.get(
      `${config.api_path}/service-categories/${id}?${queryString}`,
      { headers }
    );
    const { data } = response;
    return data;
  },

  getCustomersServiceProviders: async () => {
    const storedToken = localStorage.getItem("access_token");
    const headers = storedToken
      ? { Authorization: `Bearer ${storedToken}` }
      : {};
    const response = await axios.get(`${config.api_path}/service-providers`, {
      headers,
    });
    console.log("", response);
    return response;
  },
};
export default serviceProviderService;
