import React, { useState } from 'react';
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';
import pointerIcon from '../../assets/svg/mapPointer.svg'
import utility from '../../helpers/index'

const MapComponent = ({ properties }) => {
  const [selectedProperty, setSelectedProperty] = useState(null);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const defaultCenter = {
    lat: 0.64,  // You could adjust the default center to fit your data
    lng: 32.64,
  };

  const customMarkerIcon = {
    url: pointerIcon, // You can use any image URL here
  };

  const handleMarkerClick = (property) => {
    setSelectedProperty(property);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCK6M79qZsGKFLNnu7L3q_uUYe_2_u3hJI">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultCenter}
        zoom={7}
      >
        {/* Render markers for each property */}
        {properties.map((property,index) => (
          <Marker
            key={property.id}
            position={{
              lat: Number(property.latitude),
              lng: Number(property.longitude),
            }}
            icon={customMarkerIcon} // Set the custom icon for the marker
            onClick={() => handleMarkerClick(property)} // Set the selected property when clicked
          />
        ))}

        {/* Info window for the selected property */}
        {selectedProperty && (
          <InfoWindow
            position={{
              lat: Number(selectedProperty.latitude),
              lng: Number(selectedProperty.longitude),
            }}
            onCloseClick={() => setSelectedProperty(null)}  // Close info window when clicked
          >
            <div style={{ width: '200px' }}>
              <h6>{selectedProperty.plot_number, selectedProperty.location}</h6>
              <div className="map-details" style={{display:"flex"}}>
                 <img
                    src={selectedProperty.image}
                    alt={selectedProperty.estate_name}
                    style={{ width: '70%', height: 'auto', borderRadius: '5px' }}
                  />
                <ul>
                  <li><strong>Category:</strong> {selectedProperty.category}</li>
                  <li><strong>Size:</strong> {selectedProperty.land_size} m²</li>
                  <li><strong>Projected Cost:</strong> ugx {selectedProperty.projected_cost}</li>
                </ul>
             </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
