import React from 'react'
import Header from '../templates/Header2'
import Footer from '../templates/Footer'

const Index = ({children}) => {
    return (
      <>
        <Header color="true"/>
        <main >
            {children}
        </main>
        <Footer />
      </>
    )
  }
  
  export default Index