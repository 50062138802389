import React from 'react';
import { Pagination } from 'react-bootstrap';



const PaginationControls =  ({ totalPages, currentPage, onPageChange }) => {
    return (
        <Pagination>
            <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                    key={index + 1}
                    active={currentPage === index + 1}
                    onClick={() => onPageChange(index + 1)}
                    linkClassName='primary'
                >
                    {index + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    );
};

export default PaginationControls;