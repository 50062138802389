import axios from 'axios';
import { config } from '../config/index';

const brokerService = {
  // Fetch all agencies
  getAgencies: async () => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.get(`${config.api_path}/agencies`, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error fetching agencies:', error);
      throw error;
    }
  },

  // Fetch all house properties
  getAllHouseProperties: async () => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.get(`${config.api_path}/house-listings`, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error fetching house properties:', error);
      throw error;
    }
  },

  // Fetch all land properties
  getAllLandProperties: async () => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.get(`${config.api_path}/land-listings`, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error fetching land properties:', error);
      throw error;
    }
  },

  // Fetch house properties for a specific agency
  getAgencyHouseProperties: async (id) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.get(`${config.api_path}/agency/${id}/house-listings`, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error fetching agency house properties:', error);
      throw error;
    }
  },

  // Fetch land properties for a specific agency
  getAgencyLandProperties: async (id) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.get(`${config.api_path}/agency/${id}/land-listings`, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error fetching agency land properties:', error);
      throw error;
    }
  },

  // Create a new land listing
  createLandListing: async (formData) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.post(`${config.api_path}/land-listings`, formData, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error creating land listing:', error);
      throw error;
    }
  },

  // Create a new house listing
  createHouseListing: async (formData) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.post(`${config.api_path}/house-listings`, formData, { headers });
      return response.data.data; // Assuming the data is nested under `data`
    } catch (error) {
      console.error('Error creating house listing:', error);
      throw error;
    }
  },
};

export default brokerService;