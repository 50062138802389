import React from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { CreditCard, Info } from 'lucide-react';
import TooltipWrapper from '../common/tooltip';



export const PaymentsList = ({ items, onPaymentClick }) => {
  return (
    <ListGroup variant="flush">
      {items.map((item) => (
        <ListGroup.Item key={item.id} className="payment-history-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h6 className="mb-1">{item.name}
                <TooltipWrapper id={`due-items-${item.id}`} text={item.humanDescription}>
                  <span className="ms-2 text-primary"><Info size={16} /></span>
                </TooltipWrapper>
              </h6>
              <small className="text-muted">Due: {item.dueDate}</small>
            </div>
            <div className="d-flex align-items-center text-end">
              <span className="me-3 fw-medium">
                UGX {item.amount.toLocaleString()}
              </span>
              <Button
                variant="primary"
                size="sm"
                onClick={() => onPaymentClick({ name: item.name, amount: item.amount })}
                className="d-flex align-items-center"
              >
                <CreditCard size={16} className="me-2" />
                Pay Now
              </Button>
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};