import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Building2, Shield, Stethoscope, Bolt } from 'lucide-react';
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import utility from '../../helpers/index'
import ButtonWithIcon from "../../pages/ui/button-with-icon";
import essentialService from "../../services/essentialsService";

const slideVariants = {
    enter: (direction) => ({
        x: direction > 0 ? 100 : -100,
        opacity: 0,
        scale: 0.8,
    }),
    center: (position) => ({
        x: 0,
        opacity: 1,
        scale: position === 1 ? 1.2 : 0.9,
        zIndex: position === 1 ? 2 : 1,
        transition: {
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.3, ease: "easeIn" }
        }
    }),
    exit: (direction) => ({
        x: direction < 0 ? 100 : -100,
        opacity: 0,
        scale: 0.8,
        transition: {
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2, ease: "easeOut" }
        }
    })
};

const Essentials = () => {
    const [essentials, setEssential] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [rotators, setRotators] = useState([]);
    const [screenSize, setScreenSize] = useState('desktop');
    const [direction, setDirection] = useState(0);
    const [categoryName, setCategoryName] = useState("");

    // Helper: Extract providers for a given category and sub-category
    const getRotatorsForSubCategory = (categoryId, subCategoryId) => {
        const category = essentials.find((cat) => cat.id === categoryId);
        if (!category) return [];
        setCategoryName(() => category.name ? category.name : "Service Providers")
        const subCategory = category.service_sub_categories.data.find(
            (sub) => sub.id === subCategoryId
        );
        // Return the providers or an empty array if not found
        return subCategory?.emergency_rotators?.data || [];
    };


    const getEssentialService = async () => {
        try {
            const resp = await essentialService.getRotatorService();
            setEssential(resp.slice(0, 3)); // Limit to the first 3 items
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getEssentialService();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                setDirection(1)
                setCurrentIndex((prevIndex) =>
                    prevIndex === essentials.length - 1 ? 0 : prevIndex + 1
                );
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [isPaused, essentials.length]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 768) {
                setScreenSize('mobile');
            } else if (width <= 1024) {
                setScreenSize('tablet');
            } else {
                setScreenSize('desktop');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePrevious = () => {
        setDirection(-1);
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? essentials.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setDirection(1);
        setCurrentIndex((prevIndex) =>
            prevIndex === essentials.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleActionClick = (categoryId, subCategoryId) => {
        const providers = getRotatorsForSubCategory(categoryId, subCategoryId);
        setRotators(providers);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getVisibleEssentials = () => {
        if (!essentials || essentials.length === 0) return [];
        const visibleCount = screenSize === 'mobile' ? 1 : screenSize === 'tablet' ? 2 : 3;
        const maxVisible = Math.min(visibleCount, essentials.length); // Limit to available essentials
        const result = [];

        for (let i = 0; i < maxVisible; i++) {
            const index = (currentIndex + i) % essentials.length;
            const essential = essentials[index];

            // Assign icon based on the service name
            let icon;
            if (essential.name.toLowerCase().includes("security")) {
                icon = <Shield size={40} className="text-primary mb-3" />;
            } else if (essential.name.toLowerCase().includes("admin")) {
                icon = <Building2 size={40} className="text-primary mb-3" />;
            } else if (essential.name.toLowerCase().includes("medicare")) {
                icon = <Stethoscope size={40} className="text-primary mb-3" />;
            } else {
                icon = <Bolt size={40} className="text-primary mb-3" />; // Default icon for other services
            }

            result.push({ ...essential, icon, position: i });
        }
        return result;

    };



    return (
        <div
            className="position-relative"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            <div className="d-flex justify-content-center align-items-center">
                {(screenSize === 'tablet' || screenSize === 'desktop') && (
                    <motion.button
                        className="btn btn-link text-white p-0 me-4"
                        onClick={handlePrevious}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        aria-label="Previous"
                    >
                        <ChevronLeft size={40} />
                    </motion.button>)}

                <div className="d-flex gap-4 justify-content-center align-items-center">
                    <AnimatePresence initial="false" mode="popLayout" custom={direction}>
                        {getVisibleEssentials().map((category, index) => (
                            // <h3 className="text-primary mb-2">{category?.name?.toUpperCase()}</h3>
                            <motion.div
                                key={category?.id}
                                custom={category.position}
                                variants={slideVariants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                className="bg-white rounded-3 p-4 text-center shadow-lg"
                                style={{
                                    width: screenSize === 'mobile' ? '300px' : screenSize === 'tablet' ? '320px' : '280px',
                                    scale: screenSize === 'mobile' ? 1.1 : screenSize === 'tablet' ? 1.5 : 1,

                                    zIndex: index === 1 ? 2 : 1,
                                    transformOrigin: "center center"
                                }}
                                whileHover={{
                                    scale: category?.position === 1 ? 1.2 : 1,
                                    transition: { duration: 0.2 }
                                }}
                            >


                                {category?.icon}
                                <h5 className="text-primary mb-2">{category?.name?.toUpperCase()}</h5>
                                <p className="text-muted mb-3">"{category.description ? utility.escapeHtmlTags(category.description.slice(0, 30)) : ""}"</p>
                                <div className="d-flex flex-wrap gap-2 justify-content-center">
                                    {category.service_sub_categories.data.filter(sub => sub.emergency_rotators.data.length > 0).map((subCategory, actionIndex) => (
                                        <Button
                                            key={actionIndex}
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => handleActionClick(category.id, subCategory.id)}
                                            className="flex-grow-0 "
                                            style={{ padding: "4px", fontSize: "12px", marginRight: 0 }}
                                        >
                                            {subCategory?.name.toUpperCase()}
                                        </Button>
                                    ))}
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>

                {(screenSize === 'tablet' || screenSize === 'desktop') && (
                    <motion.button
                        className="btn btn-link text-white p-0 ms-4"
                        onClick={handleNext}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        aria-label="Next"
                    >
                        <ChevronRight size={40} />
                    </motion.button>
                )}
            </div>
            <div className="d-flex justify-content-center gap-2 mt-4">
                {essentials.map((_, index) => (
                    <motion.button
                        key={index}
                        className="btn p-0"
                        onClick={() => {
                            setDirection(index > currentIndex ? 1 : -1);
                            setCurrentIndex(index);
                        }}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <motion.div
                            className="rounded-circle"
                            initial={false}
                            animate={{
                                scale: index === currentIndex ? 1.2 : 1,
                                backgroundColor: index === currentIndex ? 'white' : 'rgba(255, 255, 255, 0.5)'
                            }}
                            transition={{ duration: 0.2 }}
                            style={{
                                width: '8px',
                                height: '8px'
                            }}
                        />
                    </motion.button>
                ))}
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{categoryName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup as="ul">
                        {rotators.length > 0 ? (
                            rotators.map((provider) => (
                                <ListGroup.Item key={provider.id} as="li">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {provider.name}
                                        </div>
                                        <div>
                                            {provider.position}
                                        </div>
                                        <div>
                                            <ButtonWithIcon
                                                iconName={faPhone}
                                                callToActionText="Call Us"
                                                href={`tel:${provider.telephone_number}`}
                                            />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            ))
                        ) : (
                            <ListGroup.Item as="li">
                                No providers available.
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Modal.Body>
            </Modal>



        </div>
    )
}

export default Essentials;
