import React from 'react'
import Home from '../pages/home/index'
import { Route, Routes } from 'react-router-dom'
import Register from '../pages/auth/register'
import Login from '../pages/auth/login'
import ForgotPassword from '../pages/auth/ForgotPass'
import ResetPassword from '../pages/auth/ResetPass'
import VerifyEmail from '../pages/auth/EmailVerifica' 
import About from '../pages/about'
import Blog from '../pages/blog'
import NoticeBoard from '../pages/noticeboard'
import Privacy from '../pages/privacy'
import Search from '../pages/search'
import UserProfile from '../pages/user-profile/user_profile'
import Estates from '../pages/estates'
import DigitalBroker from '../pages/digitalBroker'
import Activities from '../pages/activities'
import BodaBoda from '../pages/bodaBoda'
import ServiceProvider from '../pages/serviceProvider'
import Verified from '../pages/verified'
import Terms from '../pages/terms'
import ActivityDetails from '../pages/activities/view';
import EstateProfile from '../pages/profile/estate_profile';
import ListingForm from '../pages/digitalBroker/listingForm';
import LandDetails from '../pages/digitalBroker/landDetail';
import EstatePay from '../pages/estatePay';



const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/user_profile" element={<UserProfile />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/digital-broker/:id/:type" element={<DigitalBroker />} />

        <Route path="/about" element={<About />} />
        <Route path="/boda-stages" element={<BodaBoda />} />
        <Route path="/service-provider" element={<ServiceProvider />} />
        <Route path="/estate-pay" element={<EstatePay />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/estates" element={<Estates />} />
        <Route path="/noticeboard" element={<NoticeBoard />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/search" element={<Search />} />
        <Route path="/verified" element={<Verified />} />
        <Route path="/terms-of-service" element={<Terms />} />

        <Route path="/activities/view/:id" element={<ActivityDetails />} />
        <Route path="/estate_profile" element={<EstateProfile />} />
        <Route path="/listing-form" element={<ListingForm />} />
        <Route path="/land-for-sale" element={<LandDetails />} />

    </Routes>
  )
}

export default AppRoutes