import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { X } from 'lucide-react';

export const zones = [
  { id: '1', name: 'Zone A' },
  { id: '2', name: 'Zone B' },
  { id: '3', name: 'Zone C' },
  { id: '4', name: 'Zone D' },
];

export function PaymentModal({ isOpen, onClose, itemName, amount }) {
  const [zone, setZone] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [prn, setPrn] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPrn = 'PRN' + Math.random().toString(36).substr(2, 9).toUpperCase();
    setPrn(newPrn);
    setShowSuccess(true);
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{showSuccess ? 'Payment Successful' : `Pay for ${itemName}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showSuccess ? (
          <Form onSubmit={handleSubmit}>
            <p className="h5 mb-4">Amount: UGX {amount.toLocaleString()}</p>

            <Form.Group className="mb-3">
              <Form.Label>Select Zone</Form.Label>
              <Form.Select
                value={zone}
                onChange={(e) => setZone(e.target.value)}
                required
              >
                <option value="">Select a zone</option>
                {zones.map((zone) => (
                  <option key={zone.id} value={zone.id}>
                    {zone.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Payment Method</Form.Label>
              {['mtn', 'airtel', 'bank'].map((method) => (
                <Form.Check
                  key={method}
                  type="radio"
                  id={`payment-${method}`}
                  name="paymentMethod"
                  label={method === 'mtn' ? 'MTN Mobile Money' : method === 'airtel' ? 'Airtel Money' : 'Bank'}
                  value={method}
                  checked={paymentMethod === method}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  required
                />
              ))}
            </Form.Group>

            {paymentMethod && paymentMethod !== 'bank' && (
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  placeholder="Enter phone number"
                />
              </Form.Group>
            )}

            <Button type="submit" variant="primary" className="w-100">
              Submit Payment
            </Button>
          </Form>
        ) : (
          <div className="text-center">
            <div className="text-success mb-4">
              <svg
                className="bi bi-check-circle"
                width="64"
                height="64"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
            </div>
            <p className="text-muted mb-2">Your PRN number is:</p>
            <p className="h4 text-primary mb-4">{prn}</p>
            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}