import {React, useEffect} from 'react'
import Layout from '../../layout/index'
import PageIntro from '../../components/pageIntro'

const Index = () => {

  useEffect(() => {
    document.title = "Tuwe | Search";
})
  return (
    <Layout>
       <PageIntro title="Search" subtitle="Query our librally listings " />
    </Layout>
  )
}

export default Index