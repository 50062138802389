  import { React, useEffect, useState } from 'react'
  import { Container, Navbar, Nav, Image, Dropdown } from 'react-bootstrap';
  import { Menu, X, ChevronUp, ClipboardList, Bell } from 'lucide-react';
  import { motion, AnimatePresence } from 'framer-motion';
  import Logo from '../assets/images/TuweLogo.png'
  import DefaultImage from '../assets/images/dummy-avatar.jpg'
  import { Link } from 'react-router-dom';
  import authService from '../services/authService';
  import "./header2.css"
  import { toast } from 'react-toastify'


  const Header = ({ color = false }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [headerBg, setHeaderBg] = useState(false);

    const token = localStorage.getItem('access_token');
    const username = localStorage.getItem('user_name');
    const image = localStorage.getItem('image');
    const role = localStorage.getItem('role');

    console.log("Image:",image);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setHeaderBg(true);
        } else {
          setHeaderBg(false);
        }

        setShowScrollTop(window.scrollY > 300);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const handleLogout = async () => {
      try {
        await authService.logout();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || error?.message)
      } 
      
    }

    const menuVariants = {
      open: {
        opacity: 1,
        height: 'auto',
        transition: {
          type: 'spring',
          stiffness: 300,
          damping: 30,
        },
      },
      closed: {
        opacity: 1,
        height: 'auto',
        transition: {
          type: 'spring',
          stiffness: 300,
          damping: 30,
        },
      },
    };

    return (
      <>
        <Navbar
          expand="lg"
          className={`fixed-top header ${headerBg || color ? 'head-color' : 'bg-transparent'}`}
          variant="dark"
          expanded={isExpanded}
        >
          <Container>
            <Navbar.Brand as={Link} to="/">
              <Image src={Logo} height="30" alt="Tuwe Logo" />
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setIsExpanded(!isExpanded)}

            >
              {isExpanded ? <X size={30} color='#e3c42c' /> : <Menu size={30} color='#e3c42c' />}
            </Navbar.Toggle>

            <AnimatePresence>
              <motion.div
                initial="open"
                animate={isExpanded ? 'open' : 'closed'}
                variants={menuVariants}
                className="w-100"
              >
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    {!token ? (
                      <>
                        <Nav.Link as={Link} to="/about">About Us</Nav.Link>
                        {/* <Nav.Link as={Link} to="/digital-broker/1/1">Digital Broker</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/boda-stages">Boda</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/service-provider">Service Provider</Nav.Link> */}
                        <Nav.Link as={Link} to="/login">Login</Nav.Link>
                        <Nav.Link as={Link} to="/register">
                          Register
                        </Nav.Link>
                      </>
                    ) : (
                      <>
                          <Nav.Link as={Link} to="/estate_profile">My Estate</Nav.Link>
                        <Nav.Link as={Link} to="/estate-pay">Estate Pay</Nav.Link>
                        <Nav.Link as={Link} to="/digital-broker/1/1">Digital Broker</Nav.Link>
                        <Nav.Item className='nav-icon' title='Activities'>
                          <Nav.Link as={Link} to="/activities">
                            <ClipboardList size={24} aria-hidden="true" className='me-2'/>
                            <span className='mt-2'>Activities</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='nav-icon mb-2' title='Notifications'>
                            <Nav.Link as={Link} to="/noticeboard" >
                              <Bell size={24} aria-hidden="true" className='me-2' />
                            <span className='mt-2'>Notifications</span>
                          </Nav.Link>
                        </Nav.Item>

                        <Dropdown align="end">
                          <Dropdown.Toggle variant="link">
                            {image ? (
                              <Image
                              src={image}
                              roundedCircle
                              width={35}
                              height={35}
                              className="ms-2"
                              style={{objectFit: "cover"}}
                            />
                            ) : (
                              <Image
                              src={DefaultImage}
                              roundedCircle
                              width={35}
                              height={35}
                              className="ms-2"
                            />
                            ) }
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item disabled>{username ? username.split(" ")[0] : 'no user'}</Dropdown.Item>
                              <Dropdown.Item as={Link} to="/user_profile">My Profile</Dropdown.Item>
                            {/* <Dropdown.Item as={Link} to="/estate_profile">My Estate</Dropdown.Item> */}
                            { role && role !== 'Estate Member' && (
                              <Dropdown.Item as={Link} to="https://admin.tuweug.com" target="_blank">Admin Dashboard</Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </motion.div>
            </AnimatePresence>
          </Container>
        </Navbar>

        <AnimatePresence>
          {showScrollTop && (
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="scroll-top-btn"
            >
              <ChevronUp size={24} />
            </motion.button>
          )}
        </AnimatePresence>
      </>

    )
  }

  export default Header