import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import LOGO_BASE64 from '../assets/images/logo-base64';

// Global function to create a standardized PDF instance
const createPdfInstance = () => {
    const doc = new jsPDF();
    let startY = 10; // Initial Y position
    const primaryColor = '#833c99'; // Primary color

    // Add header (Company logo and address)
    const addHeader = () => {
        const imgWidth = 40; // Adjust width as needed
        const imgHeight = 20; // Adjust height as needed
        const margin = 10; // Margin from left and top

        // Header background rectangle
        doc.setFillColor(primaryColor); // Set primary color
        doc.rect(0, 0, 210, 35, 'F'); // Draw a filled rectangle for the header background

        // Insert Logo (left aligned)
        if (LOGO_BASE64) {
            doc.addImage(LOGO_BASE64, 'PNG', margin, 5, imgWidth, imgHeight);
        }

        // Company Info (address text with reduced font size and wrapped text)
        doc.setTextColor(255, 255, 255); // Set text color to white
        doc.setFontSize(10); // Reduced font size for the address

        // Text placement for company info (address)
        const addressLines = [
            'Tuwe Technologies',
            'P.O. Box 171496, Kampala, Uganda',
            'Email: info@tuweug.com | Phone: +256 708-461-780 | WhatsApp: +256 772-410-671'
        ];

        const addressX = margin + imgWidth + 10;
        let addressY = 15; // Starting Y position for address text

        addressLines.forEach((line, index) => {
            doc.text(line, addressX, addressY + (index * 7)); // Adjust vertical spacing for each line
        });

        // Draw horizontal line after header
        // doc.line(10, 35, 200, 35);

        // Set the next starting Y position dynamically
        startY = 40; // Adjusted based on header height
        doc.setTextColor(33, 37, 41); // Reset text color to black for the footer
    };

    // Add footer (Page numbers)
    const addFooter = () => {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, 105, 285, { align: 'center' });
        }
    };

    // Attach header to the first page
    addHeader();

    // Return functions for adding footer and tables
    return {
        doc,
        addFooter,
        autoTable: (options) => autoTable(doc, {
            ...options,
            headStyles: { 
                fillColor: primaryColor, // Set primary color for table headers

            },
            footStyles: {
                fillColor: primaryColor
            }
        }), // Wrapper for autoTable with primary color for header
        savePdf: (filename) => {
            addFooter(); // Add footer before saving
            doc.save(filename);
        },
        startY, // Return dynamic starting Y position
    };
};

export default createPdfInstance;
