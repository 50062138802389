import React, { useState } from 'react'
import { Badge, Button, Card, Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { Phone, Heart, MapPin, ListCollapse, MessageCircle } from 'lucide-react';
import { motion } from 'framer-motion';
// import StarRating from '../stars';
import Rating from '../common/rating';
import { config } from '../../config';

export const ProviderCard = ({ service_provider }) => {
    const [isFavorite, setIsFavorite] = useState(false); // State to manage favorite status
    const [showModal, setShowModal] = useState(false);

    const toggleFavorite = () => {
        setIsFavorite((prev) => !prev); // Toggle favorite state
    };
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <>
            <Card className="h-100 border-0 shadow-sm">
                <div className="position-relative">
                    <Card.Img variant="top" src={config.abs_path + service_provider.image} style={{ height: 'auto', width: '100%', aspectRatio: '3/2', objectFit: 'cover', backgroundColor: '#f0f' }} />

                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="position-absolute top-0 start-0 m-3"
                        style={{ cursor: 'pointer', zIndex: 1 }}
                        onClick={toggleFavorite}
                    >
                        <Heart size={24} className="text-warning" fill={isFavorite ? "#E2C032" : "none"} color='#E2C032' />
                    </motion.div>

                    <Badge bg={service_provider.status ? 'success' : 'danger'} className="position-absolute top-0 end-0 m-3">
                        {service_provider.status ? 'Open' : 'Closed'}
                    </Badge>
                </div>
                <Card.Body>
                    <Card.Title className="h5 mb-3">{service_provider?.business_name}</Card.Title>
                    <div className="d-flex align-items-center mb-3">
                        <Rating rating={service_provider?.rating || 2.5} />
                        <span className="ms-2 text-muted">
                            ({service_provider?.rating || 2.5})
                        </span>
                    </div>
                    <div className="d-flex gap-2 mb-3">
                        <Badge bg="info" className="text-white d-flex justify-content-center align-items-center">
                            <MapPin size={16} className='me-1' />
                            {service_provider?.district?.name}, {service_provider?.subcounty?.name}
                        </Badge>
                    </div>
                    <div className="d-flex gap-2">
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <Button variant="primary" href={`tel:${service_provider?.telephone_number}`} className="d-flex align-items-center gap-2">
                                <Phone size={16} />
                                Call
                            </Button>
                        </motion.div>
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <Button
                                variant="secondary"
                                href={`https://wa.me/${service_provider?.telephone_number?.replace('+', '')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-flex align-items-center gap-2"
                            >
                                <MessageCircle size={16} />
                                WhatsApp
                            </Button>
                        </motion.div>
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <Button
                                variant="outline-secondary"
                                onClick={handleShow}
                                className="d-flex align-items-center gap-2"
                            >
                                <ListCollapse size={16} />
                                Details
                            </Button>
                        </motion.div>
                    </div>
                </Card.Body>
            </Card>
            {/* Modal for service provider details */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{service_provider?.business_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <Container>
                    <Row className="mb-3">
                        <Col xs={4} className="text-center">
                            <Image
                                src={config.abs_path + service_provider.logo}
                                roundedCircle
                                fluid
                                style={{ width: '80px', height: '80px',  backgroundColor: '#f0f'}}
                            />
                        </Col>
                        <Col xs={8}>
                            <h5>{service_provider?.business_name}</h5>
                            <p><strong>Business Type: </strong><Badge bg="info" className="text-muted ">{service_provider?.type}</Badge></p>
                            <p><strong>Phone:</strong> {service_provider?.telephone_number}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><strong>Location:</strong> {service_provider?.district?.name}, {service_provider?.subcounty?.name}</p>
                            <p><strong>Description:</strong> <span dangerouslySetInnerHTML={{ __html: service_provider?.description }} /></p>
                        </Col>
                    </Row>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

