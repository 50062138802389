import axios from 'axios';
import { config } from '../config/index';
import jwtDecode from 'jwt-decode'; // Make sure to import jwtDecode

const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${config.api_path}/login`, { email, password });
      const { access_token, estate, user } = response.data;

      console.log(access_token);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("estate", estate.id);
      localStorage.setItem("user_id", user.id); // Save user ID
      localStorage.setItem("user_name", user.name); // Save user name
      localStorage.setItem("role", user.role);
      localStorage.setItem("image", user.image);
      return response.data;
    } catch (error) {
      console.error("Login failed", error);
      throw error; // Handle login failure
    }
  },

  register: async (formData) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};

      const response = await axios.post(`${config.api_path}/register`, { ...formData }, { headers });
      const { data } = response.data;
      return data;
    } catch (error) {
      console.error("Registration failed", error);
      throw error; // Handle registration failure
    }
  },

  verifyEmail: async (formData) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};

      const response = await axios.post(
        `${config.api_path}/email/verify?id=${formData.id}&expires=${formData.expires}&signature=${formData.signature}&hash=${formData.hash}`,
        {},
        { headers }
      );
      const { data } = response.data;
      return data;
    } catch (error) {
      console.error("Email verification failed", error);
      throw error; // Handle email verification failure
    }
  },

  forgotpass: async (email) => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};

      const response = await axios.post(`${config.api_path}/forgot-password`, { email }, { headers });
      const { data } = response.data;
      return data;
    } catch (error) {
      console.error("Forgot password failed", error);
      throw error; // Handle forgot password failure
    }
  },

  logout: async () => {
    try {
      const storedToken = localStorage.getItem("access_token");
  
      if (storedToken) {
        const headers = { Authorization: `Bearer ${storedToken}` };
  
        const response = await axios.post(`${config.api_path}/logout`, null, { headers });
  
        if (response.status === 200) {
          console.log("Logout successful:", response.data);
        }
      }
  
      localStorage.clear(); // Clear all localStorage items once
      window.location.href = "/"; // Redirect user
  
    } catch (error) {
      console.error("Logout failed:", error);
      throw error; // Ensure the error is properly handled
    }
  },

  getEstates: async () => {
    try {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};

      const response = await axios.get(`${config.api_path}/estates`, { headers });
      const { data } = response.data;
      return data;
    } catch (error) {
      console.error("Failed to fetch estates", error);
      throw error; // Handle error fetching estates
    }
  },

  getCurrentUser: () => {
    try {
      const token = localStorage.getItem('access_token'); // Use 'access_token' consistently

      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp < currentTime) {
          // Token has expired
          localStorage.removeItem('access_token'); // Remove expired token
          return null; // Return null if the token is expired
        }
        return decodedToken; // Return decoded token if valid
      }
      return null; // No token found
    } catch (error) {
      console.error("Error decoding token:", error);
      return null; // Return null if there's an error decoding the token
    }
  },
};

export default authService;
