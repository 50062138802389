import React from 'react'
import verifiedImg from '../../assets/images/verified-gold.fw.png'
import cartImg from '../../assets/svg/cart.svg'
import ApartmentImg from '../../assets/images/Apartment.jpg'
import BedsIcon from '../../assets/svg/beds.svg'
import BathsIcon from '../../assets/svg/baths.svg'
import LocationIcon from '../../assets/svg/location.svg';
import utility from '../../helpers/index';
import SizeIcon from '../../assets/svg/size.svg'

const index = () => {
  return (
    <div class="col-md-4 card-boda mx-2">
        <div class="img-section">
            <img height="240px" width="100%" alt="" src={ApartmentImg} />
        </div>
        <div class="small-btn">
            <img src={verifiedImg} alt="verified" width="25%" />
            </div>
            <div className='cart'>
                <img src={cartImg} alt="verified" width="75%" />
            </div>       
        <span class="title">Apartment</span>
        <div class="price-section">
            <div class="price">Ugx 92,947.03</div>
            <div className='discount'>40% Off</div>
        </div>
    <div class="details-section">
        <span class="img-sec">
            <img src={LocationIcon} alt="location-icon" />
        </span>
        <span class="loc-det">6805 Krystina Pike Apt. 373
Port Jazminview, WY 75431</span></div>
       <div class="bottom-section">
           <span class="col-md-4">
            <span class="img-sec">
                <img src={SizeIcon} alt="icon" />
            </span>
            <span>1000 sqft</span>
        </span>
        <span class="col-md-4">
            <span class="img-sec">
                <img src="/static/media/beds.6037599248a4849609bb1f9c52ab1fd8.svg" alt="icon"/>
            </span><span> Beds</span>
        </span>
        <span class="col-md-4">
            <span class="img-sec">
                <img src="/static/media/baths.c4d73c8fedfb81a203319864f0989336.svg" alt="icon"/>
            </span>
            <span> Baths</span>
        </span>
    </div></div>
  )
}

export default index