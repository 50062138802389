// src/components/Rating/Rating.tsx
import React from 'react';
import { Star, StarHalf } from 'lucide-react';
import './rating.css'; 



const Rating = ({ rating, maxStars = 5, starSize = 16 }) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const hasHalfStar = rating % 1 !== 0; // Whether there's a half star

  return (
    <div className="star-rating">
      {/* Base stars (empty) */}
      <div className="stars">
        {Array.from({ length: maxStars }, (_, index) => (
            <Star key={index} size={starSize} fill="none" strokeWidth={2} color='#833c99' />
        ))}
      </div>

      {/* Filled stars (overlay) */}
      <div className="stars rating">
        {Array.from({ length: fullStars }, (_, index) => (
            <Star key={index} size={starSize} fill="#833c99" strokeWidth={1} color='#833c99'/>
        ))}
              {hasHalfStar && <StarHalf size={starSize} fill="#833c99" strokeWidth={1} color='#833c99'/>}
      </div>
    </div>
  );
};

export default Rating;