import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { Upload } from "lucide-react";
import "../digitalBroker/listingForm.css"; // Custom CSS file
import axios from "axios"; // Import axios for API calls
import { config } from '../../config';


const propertyTypes = ["Land", "House", "Apartment"];
const categories = ["Residential", "Commercial", "Industrial"];

export default function ListingForm() {
  const [formData, setFormData] = useState({
    ownerInfo: { fullName: "", ninNumber: "", currentAddress: "", homeAddress: "", gstin: "" },
    propertyInfo: { propertyType: "", category: "", landSize: "", listingType: "sell" },
    pricing: { price: "", discountType: "amount", discountValue: "", finalPrice: "" },
    selectedAgent: null,
  });

  const [agents, setAgents] = useState([]); // State to store fetched agents
  const [loading, setLoading] = useState(true); // State to manage loading state

  // Fetch agencies from the backend
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(`${config.api_path}/agencies`); // Replace with your API endpoint
        setAgents(response.data.data); // Assuming the response has a `data` key
      } catch (error) {
        console.error("Error fetching agents:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleAgentSelect = (e) => {
    const selectedId = e.target.value;
    const selectedAgent = agents.find((agent) => agent.id.toString() === selectedId);

    setFormData((prev) => ({
      ...prev,
      selectedAgent: selectedAgent || null,
    }));
  };


  const handleChange = (e, section) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [section]: { ...prev[section], [name]: value },
    }));
    if (section === "pricing") calculateFinalPrice({ ...formData.pricing, [name]: value });
  };

  const calculateFinalPrice = (pricing) => {
    const price = parseFloat(pricing.price.replace(/,/g, "")) || 0;
    const discountValue = parseFloat(pricing.discountValue.replace(/,/g, "")) || 0;
    let finalPrice = price;

    if (pricing.discountType === "amount") {
      finalPrice = Math.max(price - discountValue, 0); // Ensure no negative price
    } else if (pricing.discountType === "percentage") {
      finalPrice = Math.max(price - (price * discountValue) / 100, 0);
    }

    setFormData((prev) => ({
      ...prev,
      pricing: { ...pricing, finalPrice: finalPrice.toLocaleString() },
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
    <Container className="mt-4">
      <Card className="shadow-sm p-4">
        <Form onSubmit={handleSubmit}>
          {/* Agent Selection */}
          <Form.Group className="mb-4">
            <Form.Label className="fw-bold">Choose Agent</Form.Label>
            {loading ? (
              <p>Loading agents...</p>
            ) : (
              <Form.Select value={formData.selectedAgent?.id || ""} onChange={handleAgentSelect} required>
                {!formData.selectedAgent && <option value="">Select an agent</option>}
                {agents.map((agent) => (
                  <option key={agent.id} value={agent.id}>
                    {agent.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>


          {/* Owner's Information */}
          <h5 className="fw-bold">Owner's Information</h5>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" name="fullName" value={formData.ownerInfo.fullName} onChange={(e) => handleChange(e, "ownerInfo")} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>NIN Number</Form.Label>
                <Form.Control type="text" name="ninNumber" value={formData.ownerInfo.ninNumber} onChange={(e) => handleChange(e, "ownerInfo")} required />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Current Address</Form.Label>
                <Form.Control type="text" name="currentAddress" value={formData.ownerInfo.currentAddress} onChange={(e) => handleChange(e, "ownerInfo")} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Home Address</Form.Label>
                <Form.Control type="text" name="homeAddress" value={formData.ownerInfo.homeAddress} onChange={(e) => handleChange(e, "ownerInfo")} required />
              </Form.Group>
            </Col>

          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Current Estate</Form.Label>
                <Form.Control type="text" name="estate" value={formData.ownerInfo.gstin} onChange={(e) => handleChange(e, "ownerInfo")} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Passport Photo</Form.Label>
                <div className="upload-box text-center">
                  <input type="file" id="passportPhoto" className="d-none" />
                  <label htmlFor="passportPhoto" className="cursor-pointer">
                    <Upload size={24} />
                    <span>Upload here</span>
                  </label>
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* Property Information */}
          <h5 className="fw-bold mt-4">Property Information</h5>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Property Type</Form.Label>
                <Form.Select name="propertyType" value={formData.propertyInfo.propertyType} onChange={(e) => handleChange(e, "propertyInfo")} required>
                  <option value="">Select type</option>
                  {propertyTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Form.Select name="category" value={formData.propertyInfo.category} onChange={(e) => handleChange(e, "propertyInfo")} required>
                  <option value="">Select category</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Land Size</Form.Label>
                <Form.Control type="text" name="landSize" value={formData.propertyInfo.landSize} onChange={(e) => handleChange(e, "propertyInfo")} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Listing Type</Form.Label>
                <div className="d-flex gap-3">
                  <Form.Check type="radio" label="Sell" name="listingType" value="sell" checked={formData.propertyInfo.listingType === "sell"} onChange={(e) => handleChange(e, "propertyInfo")} />
                  <Form.Check type="radio" label="Rent" name="listingType" value="rent" checked={formData.propertyInfo.listingType === "rent"} onChange={(e) => handleChange(e, "propertyInfo")} />
                  <Form.Check type="radio" label="Lease" name="listingType" value="lease" checked={formData.propertyInfo.listingType === "lease"} onChange={(e) => handleChange(e, "propertyInfo")} />
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* Pricing Section */}
          <h5 className="fw-bold mt-4">Pricing</h5>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={formData.pricing.price}
                  onChange={(e) => handleChange(e, "pricing")}
                  required
                />                </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Discount Type</Form.Label>
                <Form.Select name="discountType" value={formData.pricing.discountType} onChange={(e) => handleChange(e, "pricing")}>
                  <option value="amount">Amount</option>
                  <option value="percentage">Percentage</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Discount Value</Form.Label>
                <Form.Control
                  type="text"
                  name="discountValue"
                  value={formData.pricing.discountValue}
                  onChange={(e) => handleChange(e, "pricing")}
                />                </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Final Price</Form.Label>
                <Form.Control type="text" value={formData.pricing.finalPrice} readOnly />
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit" variant="primary" className="mt-3 w-100">
            Submit
          </Button>
        </Form>
      </Card>
    </Container>
  );
}
