
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import ListingForm from "../../components/digitalBroker/ListingForm";


export default function PropertyForm() {
 
  return (
    <Layout>
      <PageIntro title="Create Property for Listing" />
      <ListingForm />
    </Layout>
  );
}
