import React, {  useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import Essentials from '../../components/essentials';
import estateService from '../../services/estateService';
import Essentials from '../essentials/essentials';

const Index = () => {
  const [estate, setEstate] = useState({});
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const fetchEstate = async () => {
    try {
      const estateID = localStorage.getItem('estate');
      const response = await estateService.getEstate(estateID);
      setEstate(response.data.data);
      setLoading(false); // Stop loading once data is fetched
    } catch (error) {
      setLoading(false); // Stop loading
      setError(error.message || 'An error occurred while fetching the estate');
    }
  };

  useEffect(() => {
    fetchEstate();
  }, []); // Only run once on component mount

  const backgroundImage = estate.image || 
    'https://tuweug.com/static/media/assetsTwo.d584fcf53446d78eeb9b.jpg' ||
    'https://images.unsplash.com/photo-1449824913935-59a10b8d2000?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80';


  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.6 }}
      className="rotator position-relative"
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={12} className="text-center text-white">
            <motion.h1
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: inView ? 0 : 20, opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              className="display-3 fw-bold mb-3 position-relative"
            >
              {estate.name || "Estate Directory"}
              <span
                style={{
                  position: "absolute",
                  bottom: "-10px",
                  left: "25%",
                  width: "48%",
                  height: "2px",
                  backgroundColor: "#cbad2d",
                }}
              />
            </motion.h1>

            <motion.h5
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: inView ? 0 : 20, opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.4, duration: 0.6 }}
              className="fst-italic fw-light mb-5"
            >
              {estate.subtitle || '"Connecting Communities"'}
            </motion.h5>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: inView ? 0 : 20, opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.6, duration: 0.6 }}
            >
              <Essentials />
            </motion.div>
          </Col>
        </Row>
      </Container>

      {loading && (
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {/* {error && (
        <div className="position-absolute top-0 start-50 translate-middle-x p-3">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>
      )} */}
    </motion.div>
  );
};

export default Index;
