import React, { useState } from 'react'
import { Badge, Button, Card } from 'react-bootstrap';
import { MessageCircle, Phone, Heart,  MapPin } from 'lucide-react';
import { motion } from 'framer-motion';
// import StarRating from '../stars';
import Rating from '../common/rating';

export const RiderCard = ({ rider, stages }) => {
  const [isFavorite, setIsFavorite] = useState(false); // State to manage favorite status

  const toggleFavorite = () => {
    setIsFavorite((prev) => !prev); // Toggle favorite state
  };
  return (
    <Card className="h-100 border-0 shadow-sm">
      <div className="position-relative">
        <Card.Img variant="top" src={rider.image} style={{ height: 'auto', width: '100%', aspectRatio: '9/8', objectFit: 'contain', backgroundColor: '#833c99' }} />
        
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="position-absolute top-0 start-0 m-3"
          style={{ cursor: 'pointer', zIndex: 1 }}
          onClick={toggleFavorite}
        >
          <Heart size={24} className="text-danger" fill={isFavorite ? "#E2C032" : "none"} color='#E2C032'/>
        </motion.div>

        <Badge bg={rider.isOnDuty ? 'success' : 'danger'} className="position-absolute top-0 end-0 m-3">
          {rider.isOnDuty ? 'On Duty' : 'Off Duty'}
        </Badge>
      </div>
      <Card.Body className='align-items-center'>
        <Card.Title className="mb-3 text-center">{rider?.name}</Card.Title>
        <div className="d-flex align-items-center mb-3">
          <Rating rating={rider?.rating} />
          <span className="ms-2 text-muted">
            ({rider?.rating})
          </span>
        </div>
        <div className="d-flex gap-2 mb-3">
          <Badge bg="info" className="text-white d-flex justify-content-center align-items-center">
            <MapPin size={16} className='me-1'/>
            {stages.find(s => s.id === rider.stage)?.location}
          </Badge>
        </div>
        <div className="d-flex gap-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button variant="primary" href={`tel:${rider.phone}`} className="d-flex align-items-center gap-2">
              <Phone size={16} />
              Call
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant="outline-secondary"
              href={`https://wa.me/${rider?.phone?.replace('+', '')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex align-items-center gap-2"
            >
              <MessageCircle size={16} />
              WhatsApp
            </Button>
          </motion.div>
        </div>
      </Card.Body>
    </Card>
  )
};

