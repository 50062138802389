import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import brokerService from '../../services/brokerService';
import { Link } from 'react-router-dom'
import './index.css';
import Filters from '../common/filter';
import { AnimatePresence, motion } from 'framer-motion';
import ListingCard from './ListingCard';
import PaginationControls from '../common/pagination';
import ListingForm from './ListingForm';
import PropertyFilters from './PropertyFilters';

const types = ["land", "property"];

const DigitalBroker = () => {
    // State for the scroll position
    const [agencies, setAgencies] = useState([]); // Agencies list
    const [properties, setProperties] = useState([]); // Properties list
    const [activeType, setActiveType] = useState(0); // Default active service
    const [activeAgency, setActiveAgency] = useState(0); // Default active agency
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [perPage] = useState(4);


    const estateID = localStorage.getItem("estate");

    // Fetch agencies from the backend
    const getAgency = async () => {
        try {
            const resp = await brokerService.getAgencies();
            setAgencies(resp);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch all land listings
    const getAllLandListings = async () => {
        try {
            const resp = await brokerService.getAllLandProperties();
            setProperties(resp);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch all property listings
    const getAllPropertyListings = async () => {
        try {
            const resp = await brokerService.getAllHouseProperties();
            setProperties(resp);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch agency land listings
    const getAgencyLandListings = async (id) => {
        try {
            const resp = await brokerService.getAgencyLandProperties(id);
            setProperties(resp);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch agency property listings
    const getAgencyPropertyListings = async (id) => {
        try {
            const resp = await brokerService.getAgencyHouseProperties(id);
            setProperties(resp);
        } catch (error) {
            console.log(error);
        }
    };

    // Handle type selection (Land/Property/Bidding)
    const handleTypeClick = (index) => {
        setActiveType(index);
        if (index === 0) {
            getAllLandListings();
        } else if (index === 1) {
            getAllPropertyListings();
        }
    };

    // Handle agency selection
    const handleAgencyClick = (id) => {
        setActiveAgency(id);
        if (id === 0) {
            if (activeType === 0) {
                getAllLandListings(); // All properties if 'All' is selected
            } else {
                getAllPropertyListings()
            }
        } else {
            // Fetch agency-specific listings
            if (activeType === 0) {
                getAgencyLandListings(id);
            } else if (activeType === 1) {
                getAgencyPropertyListings(id);
            }
        }
    };

    useEffect(() => {
        getAgency();
        if (activeType === 0) {
            getAllLandListings();
        } else if (activeType === 1) {
            getAllPropertyListings();
        }
    }, [activeType]); // Effect reruns when activeType changes

    const filteredListings = activeAgency === 0
        ? properties
        : properties && properties.length > 0
            ? properties.filter(property => property.agency_id === activeAgency)
            : [properties].filter(property => property.agency_id === activeAgency);

    const indexOfLastListing = currentPage * perPage;
    const indexOfFirstListing = indexOfLastListing - perPage;
    const currentListings = filteredListings.slice(indexOfFirstListing, indexOfLastListing);
    const totalPages = Math.ceil(filteredListings.length / perPage);

    return (
        <section className="digital-broker" id="digital-broker">
            <Container>
                <Row>
                    <div className="page-intro">
                        <h3>Digital Broker</h3>
                        <p>Your vetted and verified properties</p>
                    </div>
                </Row>
                <Row>
                    <ul className="broker-type">
                        {types.map((type, index) => (
                            <li
                                key={index}
                                className={activeType === index ? 'active' : ''}
                                onClick={() => handleTypeClick(index)}
                            >
                                {type.toLocaleUpperCase()}
                            </li>
                        ))}
                        { estateID && (
                            <li
                            key={"form"}
                            className={activeType === "form" ? 'active' : ''}
                            onClick={() => setIsModalOpen(true)}
                        >
                            {"Forms".toLocaleUpperCase()}
                        </li>
                        )}
                        
                    </ul>
                </Row>

                <Row className='centralise'>
                    <Filters
                        options={[
                            { id: 0, label: 'Featured', },
                            ...agencies.map((agency, index) => ({
                                id: agency.id,
                                label: agency.name,

                            }))]}
                        activeFilter={activeAgency}
                        onFilterChange={(filterId) => handleAgencyClick(filterId)}


                    />
                </Row>

                <Row>
                    <Col xs={12} md={4}>
                        <PropertyFilters />
                    </Col>

                    <Col xs={12} md={8}>
                    {/*<Row gt={2}> */}
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={activeType}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Row xs={1} md={2} lg={2} className="g-2 justify-content-center">
                                {currentListings && currentListings.map((listing) => (
                                    <Col key={listing.id}>
                                        <ListingCard listing={listing} />
                                    </Col>
                                ))}
                            </Row>
                        </motion.div>
                    </AnimatePresence>
                    {/*</Row> */}

                    {totalPages > 1 && (
                        <Row className="mt-5">
                            <Col className="d-flex justify-content-center">
                                <PaginationControls
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    )}
                    </Col>

                </Row>
                

            </Container>

            <Modal
                size='lg'
                show={isModalOpen}
                onHide={() => {
                    setIsModalOpen(false);
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Property for Listing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListingForm />
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default DigitalBroker;
