import React from 'react'
import LocationIcon from '../../assets/svg/location.svg';
import SizeIcon from '../../assets/svg/size.svg'
import verifiedImg from '../../assets/images/verified-gold.fw.png'
import cartImg from '../../assets/svg/cart.svg'
import landImg from '../../assets/images/land.jpeg'
import utility from '../../helpers/index';

import './index.css'
const Index = () => {
  return (
    <div class="col-md-4 card-boda mx-2">
    <div class="img-section">
     <img src={landImg} height="240px" width="100%" alt="" />
   </div>
   <div class="small-btn">
   <img src={verifiedImg} alt="verified" width="25%" />
   </div>
   <div className='cart'>
    <img src={cartImg} alt="verified" width="75%" />
   </div>
   <span class="title">Plot 456 Niafurt</span>
   <div class="price-section">
     <div class="price">Ugx 922,161.60</div>
     <div className='discount'>40% Off</div>
   </div>
   <div class="details-section">
      <span class="img-sec">
          <img src={LocationIcon} alt="icon" /></span>
          <span class="loc-det">47752 Agustina Flats Suite 515
 Sauerport, NH 68787-4728</span>
         </div>
         <div class="bottom-section">
           <span class="col-md-5">
             <span class="img-sec">
               <img src={SizeIcon} alt="icon"/></span>
               <span>533.16 decimals</span>
             </span>
             <span class="col-md-5">
               <span class="img-sec">
                 <img src="/static/media/beds.6037599248a4849609bb1f9c52ab1fd8.svg" alt="icon"/>
               </span>
               <span>Kabaka Land</span>
               </span></div>
               <div class="centralise row">
                  <button style={{height: "35px;"}} class="col-md-3 btn btn-primary">Details</button>
               </div>
    </div>
  )
}

export default Index