import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'

const ButtonWithIcon = ({ iconName, callToActionText, href }) => {
  return (
    <Button href={href ? href : "#"} variant="outline-primary" size="sm">
      <FontAwesomeIcon icon={iconName} className="me-2" />
        {callToActionText}
    </Button>
  )
}

export default ButtonWithIcon