import { ArrowLeftIcon } from "lucide-react";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Index = (props) => {
  const navigate = useNavigate(); // Initialize navigate
  return (
    <Row className="mb-4">
      <Col>
        <Button
          variant="outline-primary"
          onClick={() => navigate(-1)}
          {...props}
        >
          <ArrowLeftIcon size={16} className="me-2" />
          Back
        </Button>
      </Col>
    </Row>
  );
};

export default Index;
