import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Accordion, Container } from 'react-bootstrap';

const files = [
  { key: 'terms', label: 'Terms and Conditions', file: '/terms-and-conditions-tuwe.md' },
  { key: 'guidelines', label: 'Community Guidelines', file: '/community-guidelines.md' },
  { key: 'privacy', label: 'Privacy Policy', file: '/privacy-policy.md' },
];

function PrivacyPolicy() {
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.allSettled(
      files.map(({ key, file }) =>
        fetch(file)
          .then((res) => (res.ok ? res.text() : Promise.reject(`Failed to load ${file}`)))
          .then((text) => ({ key, text }))
          .catch(() => ({ key, text: 'Error loading content.' }))
      )
    ).then((results) => {
      const contentData = results.reduce((acc, result) => {
        if (result.status === 'fulfilled') {
          acc[result.value.key] = result.value.text;
        } else {
          acc[result.reason.key] = 'Error loading content.';
        }
        return acc;
      }, {});

      setContent(contentData);
      setLoading(false);
    });
  }, []);

  return (
    <Container className="privacy-policy">
      <h2>Terms of Service</h2>
      {loading ? <p>Loading...</p> : (
        <Accordion defaultActiveKey="terms">
          {files.map(({ key, label }) => (
            <Accordion.Item key={key} eventKey={key}>
              <Accordion.Header>{label}</Accordion.Header>
              <Accordion.Body>
                <ReactMarkdown>{content[key] || 'Content not available.'}</ReactMarkdown>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </Container>
  );
}

export default PrivacyPolicy;
