import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import Filters from '../common/filter';
import PaginationControls from '../common/pagination';
import './index.css';
import serviceProviderService from '../../services/serviceProviderService';
import { ProviderCard } from './providerCard';


const types = ["Emergency", "Essential", "Desirable"];

const ServiceProviders = () => {
    const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });
    const [currentPage, setCurrentPage] = useState(1);
    const [servicesPerPage] = useState(3);
    const [loading, setLoading] = useState(false);
    const [activeType, setActiveType] = useState(0);
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeSubCategory, setActiveSubCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [services, setServices] = useState([]);

    const getEssentials = async () => {
        try {
            const resp = await serviceProviderService.getEssentialCategories();
            setCategories(resp);
        } catch (error) {
            console.error(error);
        }
    };

    const getRelevant = async () => {
        try {
            const resp = await serviceProviderService.getRelevantCategories();
            setCategories(resp);
        } catch (error) {
            console.error(error);
        }
    };

    const getDesirable = async () => {
        try {
            const resp = await serviceProviderService.getDesireableCategories();
            setCategories(resp);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            toast.error("Failed to get Services")
        }
    };



    useEffect(() => {
        getEssentials();
    }, []);

    useEffect(() => {
        if (activeCategory !== 0) {
            const getServices = async () => {
                try {
                    const resp = await serviceProviderService.getServiceProviders(activeCategory);

                    if (resp.sub_categories && resp.sub_categories.length > 0) {
                        setServices(resp.sub_categories.filter(item => item.id === activeSubCategory)[0].service_providers);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            setLoading(false)
            getServices();
        }
    }, [activeCategory, activeSubCategory]);


    const handleTypeClick = (index) => {
        setActiveType(index);
        setActiveCategory(0);
        setActiveSubCategory(0);
        setServices([]);
        setSubCategories([]);

        if (index === 0) getEssentials();
        else if (index === 1) getRelevant();
        else if (index === 2) getDesirable();
    };

    const handleCategoryClick = (id) => {
        setActiveCategory(id);
        setSubCategories(categories.filter(item => id === item.id)[0].service_sub_categories.data);
        setActiveSubCategory(0);
        setServices([]);
    };


    const filteredServices = activeSubCategory === 'all'
        ? services
        : services && services.length > 0
            ? services.filter(service => service.service_sub_category_id === activeSubCategory)
            : [services].filter(service => service.service_sub_category_id === activeSubCategory);


    const indexOfLastRider = currentPage * servicesPerPage;
    const indexOfFirstRider = indexOfLastRider - servicesPerPage;
    const currentProviders = filteredServices.slice(indexOfFirstRider, indexOfLastRider);
    const totalPages = Math.ceil(filteredServices.length / servicesPerPage);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { staggerChildren: 0.1 },
        },
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { type: "spring", stiffness: 300, damping: 30 },
        },
    };

    // Loading state
    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }


    return (
        <section className="service-provider">
            <Container ref={ref}>
                <motion.div variants={containerVariants} initial="hidden" animate={inView ? 'visible' : 'hidden'}>
                    <Row className="text-center  page-intro">
                        <Col>
                            <h3 className="mb-3">Estate Service Providers</h3>
                            <p className="">Your verified service providers</p>
                        </Col>
                    </Row>

                    <Row>
                        <ul className="services-type"
                        >
                            {types.map((type, index) => (
                                <li
                                    key={index}
                                    className={activeType === index ? 'active' : ''}
                                    onClick={() => handleTypeClick(index)}
                                >
                                    {type} Service
                                </li>
                            ))}
                        </ul>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <Filters
                                options={categories.map((category) => ({
                                    id: category.id,
                                    label: category.name,

                                }))}
                                activeFilter={activeCategory}
                                onFilterChange={(filterId) => handleCategoryClick(filterId)}
                                style={{
                                    margin: '4px',
                                    borderRadius: '8px',
                                    border: '1px solid #ddd',
                                    padding: '24px',
                                }}

                            />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <Filters
                                options={subCategories.map((subCategory) => ({
                                    id: subCategory.id,
                                    label: subCategory.name,

                                }))}
                                activeFilter={activeSubCategory}
                                onFilterChange={(filterId) => setActiveSubCategory(filterId)}
                            />
                        </Col>
                    </Row>

                    <Row className="g-4">
                        <AnimatePresence mode="wait">
                            {currentProviders && currentProviders.map((service, index) => (
                                <Col key={index} lg={4} md={6}>
                                    <motion.div variants={itemVariants} layout initial="hidden" animate="visible" exit="hidden">
                                        <ProviderCard service_provider={service} />
                                    </motion.div>
                                </Col>
                            ))}
                        </AnimatePresence>
                    </Row>

                    {totalPages > 1 && (
                        <Row className="mt-5">
                            <Col className="d-flex justify-content-center">
                                <PaginationControls
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    )}
                </motion.div>
            </Container>
        </section>
    );
};

export default ServiceProviders;