import { useState, useRef } from "react";
import { OverlayTrigger, Overlay, Tooltip } from "react-bootstrap";

/**
 * TooltipWrapper Component
 * 
 * Props:
 * @param {string} id (string): Unique ID for the tooltip.
 * @param {string} text (string): Tooltip content.
 * @param {string} placement: Tooltip placement (top, right, bottom, left). Default: "top".
 * @param {string[]} trigger: Trigger event(s) (hover, click, focus, manual). Default: ["hover", "focus"].
 * @param {object} overlayProps: Additional OverlayTrigger props.
 * @param {React.ReactNode} children: Element that triggers the tooltip.
 * @param {boolean} useOverlay: If true, use Overlay instead of OverlayTrigger (for manual control).
 */
const TooltipWrapper = ({ id, text, placement = "top", trigger = ["hover", "focus"], overlayProps = {}, children, useOverlay = false }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  if (useOverlay) {
    return (
      <>
        <span ref={target} onClick={() => setShow(!show)}>{children}</span>
        <Overlay target={target.current} show={show} placement={placement} {...overlayProps}>
          {(props) => (
            <Tooltip id={id} {...props}>
              {text}
            </Tooltip>
          )}
        </Overlay>
      </>
    );
  }

  return (
    <OverlayTrigger placement={placement} trigger={trigger} overlay={<Tooltip id={id}>{text}</Tooltip>} {...overlayProps}>
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default TooltipWrapper;
