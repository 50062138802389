
import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import brokerService from '../../services/brokerService';
import LandItem from '../../components/landItem'
import './index.css';
import verifiedImg from '../../assets/images/verified-gold.fw.png'
import Layout from '../../layout/index';
import PageIntro from '../../components/pageIntro';
import MapContainer from '../../components/map';
import { useParams } from 'react-router-dom';
import PropertyItem from '../../components/propertyItem'
import { Link } from 'react-router-dom';
import Backbtn from '../../components/backbtn';

import DigitalBroker from '../../components/digitalBroker/digitalBroker';



const Index = () => {
  const {id, type} =useParams()

  const getSubtitle = () => {
    return (id == 1 && type == 1) ?
      "Your Verified Land for Sale" :
      (id == 1 && type == 2) ? "Your Verified Land for Rent" :
        (id == 2 && type == 1) ? "Your Verified Houses for Sale" :
          (id == 2 && type == 2) ? "Your Verified Houses for Rent" :
            ""
  }

  return (
    <Layout>
      <PageIntro title="Digital Broker" subtitle={getSubtitle()} />
      <Container>
        <br />
        <Backbtn />

        <Row>
          <aside className="sidebar col-md-3">
            <h4>Filter</h4>
            <div className="filter-type">
              <label>By Status</label>
              <select name="status" type="text">
                <option value="rent">Rental</option>
                <option value="sale">On Sale</option>
                <option value="sold">Sold</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Type</label>
              <select name="type" type="text" onChange={(e) => console.log("clicked")}>
                <option value="0">Land</option>
                <option value="1">Property</option>
                <option value="2">Bidding</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Agency</label>
              <select name="agency" type="text" onChange={(e) => console.log("clicked")}>
                <option value="0">All Agencies</option>
                {/* {agencies.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))} */}
              </select>
            </div>
            <div className="filter-type">
              <label>By Category</label>
              <select name="category" type="text">
                <option value="mailo">Mailo Land</option>
                <option value="kabaka">Kabaka Land</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Price</label>
              <div className="price-section">
                <input type="text" name="min-price" style={{ width: '45%' }} placeholder="Min" />
                <input type="text" name="max-price" style={{ width: '45%' }} placeholder="Max" />
              </div>
            </div>
            <div className="filter-type">
              <label>By District</label>
              <select name="district" type="text">
                <option value="kampala">Kampala</option>
                <option value="wakiso">Wakiso</option>
                <option value="entebbe">Entebbe</option>
                <option value="mukono">Mukono</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Constituency</label>
              <select name="constituency" type="text">
                <option value="kira">Kira</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Parish</label>
              <select name="parish" type="text">
                <option value="kito">Kito</option>
              </select>
            </div>
            <div className="filter-type">
              <label>By Village</label>
              <select name="village" type="text">
                <option value="kito">Kito</option>
              </select>
            </div>
          </aside>
          <div className="col-md-9 bodas" style={{ backgroundColor: '#ccc', padding: '0', marginLeft: '20px', marginTop: '30px' }}>
            {/* <MapContainer properties={properties} /> */}
          </div>
        </Row>
        <Row>
          <div className='verified' style={{textAlign: "center"}}>
            <Link to="/verified">
              <img src={verifiedImg} alt="verified" height="100px" />
            </Link>
            <h4 style={{ marginTop: "30px", marginLeft: "20px" }}>{getSubtitle()}</h4>
          </div>
         
          
        </Row>
        <DigitalBroker />
      </Container>
    </Layout>
  );
};

export default Index;
