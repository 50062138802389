import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';  // solid star
import { faStar as faRegStar } from '@fortawesome/free-regular-svg-icons'; 

const StarRating = ({ rating }) => {
  const totalStars = 5;
  const stars = [];

  // Generate stars based on the rating
  for (let i = 1; i <= totalStars; i++) {
    if (i <= rating) {
      stars.push(faStar); // Full star
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      stars.push(faStarHalfAlt); // Half star
    } else {
      stars.push(faRegStar); // Empty star
    }
  }

  return (<>
  {stars.map((star, index) => (
        <FontAwesomeIcon key={index} icon={star} />
      ))}
  </>
      
  );
};

export default StarRating;