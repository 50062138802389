import React, { useEffect, useState } from "react";
import Layout from "../../layout/index";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PageIntro from "../../components/pageIntro";
import "./index.css";
import activitiesService from "../../services/activitiesService";
import Backbtn from '../../components/backbtn';


const ActivityDetails = () => {
  const { id } = useParams(); // Get the activity ID from the URL
  const [activity, setActivity] = useState(null); // State to hold the activity details
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [error, setError] = useState(null); // State to handle errors

  // Fetch the activity details when the component mounts or when the ID changes
  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const activityResponse = await activitiesService.getActivity(id); // Fetch activity by ID
        setActivity(activityResponse); // Set the activity details in state
      } catch (error) {
        setError("Failed to fetch activity details"); // Handle errors
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchActivity(); // Call the fetch function
  }, [id]);

  // Update the document title when the activity details are fetched
  useEffect(() => {
    if (activity) {
      document.title = `Tuwe | ${activity.name}`;
    }
  }, [activity]);

  // Display a loading message while fetching data
  if (loading) {
    return <div className="text-center my-5">Loading...</div>;
  }

  // Display an error message if fetching fails
  if (error) {
    return <div className="text-center my-5">{error}</div>;
  }

  // Display a message if the activity is not found
  if (!activity) {
    return <div className="text-center my-5">Activity not found</div>;
  }

  // Render the activity details
  return (
    <Layout>
      <PageIntro title="Estate Activities" subtitle={activity.name} />

      <Container style={{ marginTop: "40px" }}>
      <Backbtn />

        <Row className="align-items-center">
          {/* Large Image Section */}
          <Col md={6} className="mb-4">
            <div className="image-container">
              <Image
                src={
                  activity.image
                    ? activity.image
                    : "https://as2.ftcdn.net/v2/jpg/02/51/95/53/1000_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg"
                }
                fluid
                className="rounded shadow-lg activity-image"
              />
            </div>
          </Col>

          {/* Details Section */}
          <Col md={6}>
            <div className="details-box p-4 rounded shadow-sm">
              <h2 className="activity-title">{activity.name}</h2>
              <p className="activity-dates">
                <strong>📅 Start Date:</strong> {activity.start_date} <br />
                <strong>📅 End Date:</strong> {activity.end_date}
              </p>
              <p className="activity-description">
                {" "}
                <div
                  dangerouslySetInnerHTML={{ __html: activity.description }}
                />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ActivityDetails;
