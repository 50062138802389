import { React, useEffect, useState} from 'react'
import { Container, Row } from 'react-bootstrap';
import Logo from '../../assets/images/TuweLogo.png';
import { Link } from 'react-router-dom';

const Index = () => {

   const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const reviews = [
    {
      rating: '⭐⭐⭐⭐⭐',
      text: `"Tuwe has revolutionized estate management for us. Automated fee collection, accurate records, and real-time updates have eliminated the inefficiencies we struggled with for years. This platform is a must-have for estate managers."`,
      user: '— Patrick K., Estate Manager at Canaan Estate Nakwero',
      image: './images/faces/face8.jpg'
    },
    {
      rating: '⭐⭐⭐⭐⭐',
      text: `"Tuwe's customer support is excellent. Whenever we had an issue, their team responded promptly and helped us resolve it. Highly recommend!"`,
      user: '— Sarah M., Property Owner at Hilltop Properties',
      image: './images/faces/face7.jpg'
    },
    {
      rating: '⭐⭐⭐⭐⭐',
      text: `"Using Tuwe has significantly improved our property management efficiency. The platform's ease of use and its features have saved us hours of work every week."`,
      user: '— John D., Estate Manager at Greenfield Estates',
      image: './images/faces/face6.jpg'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 5000); // Change review every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [reviews.length]);

  return (
    <section className="advert col-sm-12 col-lg-5 col-md-6">
      <Container>
        <Row>
          <div className="col-md-6 logo">
            <Link to="/">
              <img src={Logo} alt="tuwe" style={{ height: "30px" }} />
            </Link>
          </div>
          <div className="promise col-md-12">
            <p>
              <h5>Our Promise</h5>
              "At Tuwe Technologies, we don’t just deliver solutions, we create
              opportunities."
            </p>
            <strong style={{ fontSize: "18px" }}>
              Join us today and experience the transformation
            </strong>
          </div>
          <div className="reviews col-md-12">
            <div
              className="review-slider"
              style={{ transform: `translateX(-${currentReviewIndex * 105}%)` }}
            >
              <ul>
                {reviews.map((review, index) => (
                  <li key={index}>
                    <div className="userImage col-md-3">
                      <img src={review.image} alt="user" />
                    </div>
                    <div className="content col-md-9">
                      {review.rating}
                      <br />
                      {review.text}
                      <span className="user-name">{review.user}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Index