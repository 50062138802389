import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../config";
import Spinner from "../../components/spinner";
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import Backbtn from "../../components/backbtn";
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import DefaultImage from '../../assets/images/dummy-avatar.jpg'

const UserProfile = () => {
  const [userData, setUserData] = useState({ customer: {} });
  const [loading, setLoading] = useState(false);
  const [editingProfile, setEditingProfile] = useState(false);
  const [editingDetails, setEditingDetails] = useState(false);
  const [editForm, setEditForm] = useState({

    name: "",
    email: "",
    phone_number: "",
    whatsapp_number: "",
    secondary_mobile: "",
    primary_emergency_contact: "",
    secondary_emergency_contact: "",
    occupation: "",
    id_type: "",
    id_number: "",
    gender: "",
    date_of_birth: "",
    nationality: "",
    plot_number: "",
    block_number: "",
    estate_zone: "",
    street: "",
    house_number: "",
    max_residence: "",
    min_residence: "",
    number_of_adults: "",
    number_of_children: "",
    box_number: "",
    
    image: null,
  });
  
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  
  const [passwordErrors, setPasswordErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  
  const [imagePreview, setImagePreview] = useState("");
  const storedToken = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        if (!storedToken) {
          toast.error("Authentication token is missing. Please log in again.");
          return;
        }

        const response = await axios.get(`${config.api_path}/profile`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const result = response.data;

        if (result) {
          const user = result.user || {};
          setUserData({
            ...user,
            customer: user.customer || {}
          });

          setEditForm({
            name: user.name || "",
            email: user.email || "",
            phone_number: user.phone_number || "",
            secondary_mobile: user.secondary_mobile || "",

            whatsapp_number: user.customer?.whatsapp_number || "",
            primary_emergency_contact: user.customer?.primary_emergency_contact || "",
            secondary_emergency_contact: user.customer?.secondary_emergency_contact || "",
            occupation: user.customer?.occupation || "",
            id_type: user.customer?.id_type || "",
            id_number: user.customer?.id_number || "",
            gender: user.customer?.gender || "",
            date_of_birth: user.customer?.date_of_birth || "",
            nationality: user.customer?.nationality || "",
            plot_number: user.customer?.plot_number || "",
            block_number: user.customer?.block_number || "",
            estate_zone: user.customer?.estate_zone || "",
            street: user.customer?.street || "",
            house_number: user.customer?.house_number || "",
            max_residence: user.customer?.max_residence || "",
            min_residence: user.customer?.min_residence || "",
            number_of_adults: user.customer?.number_of_adults || "",
            number_of_children: user.customer?.number_of_children || "",
            box_number: user.customer?.box_number || "",
            
            image: null,
          });

          setImagePreview(user.image || DefaultImage);
        }
      } catch (error) {
        toast.error("Failed to fetch user data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [storedToken]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditForm({
      ...editForm,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditForm({
        ...editForm,
        image: file,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
    setPasswordErrors(prev => ({
      ...prev,
      [name]: "",
    }));
  };

  const validatePasswordForm = () => {
    let isValid = true;
    const newErrors = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (!passwordForm.currentPassword) {
      newErrors.currentPassword = "Current password is required";
      isValid = false;
    }

    if (!passwordForm.newPassword) {
      newErrors.newPassword = "New password is required";
      isValid = false;
    } else if (passwordForm.newPassword.length < 8) {
      newErrors.newPassword = "Password must be at least 8 characters";
      isValid = false;
    }

    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setPasswordErrors(newErrors);
    return isValid;
  };

  const handleSubmitImage = async (e) => {
    e.preventDefault();

    if (!editForm.image) {
      toast.error("Please select an image to upload");
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("image", editForm.image);
      const response = await axios.post(
        `${config.api_path}/profile/update-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.message === "Profile image updated successfully") {
        toast.success("Profile image updated successfully!");
        const image_url = config.abs_path + response.data.image_url;
        localStorage.setItem("image", image_url); // Update image url in storage
        setUserData(prev => ({
          ...prev,
          image: image_url,
        }));
        setEditingProfile(false);
      }
    } catch (error) {
      console.error("Update error:", error);
      if (error.response?.data?.errors) {
        Object.values(error.response.data.errors).forEach((err) => {
          toast.error(err[0]);
        });
      } else {
        toast.error(
          error.response?.data?.message ||
            "Failed to update image. Please try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.put(
        `${config.api_path}/profile/update-details`,
        {
          // User fields
          name: editForm.name,
          phone_number: editForm.phone_number,
          
          // Customer fields
          whatsapp_number: editForm.whatsapp_number,
          secondary_mobile: editForm.secondary_mobile,
          primary_emergency_contact: editForm.primary_emergency_contact,
          secondary_emergency_contact: editForm.secondary_emergency_contact,
          occupation: editForm.occupation,
          id_type: editForm.id_type,
          id_number: editForm.id_number,
          gender: editForm.gender,
          date_of_birth: editForm.date_of_birth,
          nationality: editForm.nationality,
          plot_number: editForm.plot_number,
          block_number: editForm.block_number,
          estate_zone: editForm.estate_zone,
          street: editForm.street,
          house_number: editForm.house_number,
          max_residence: editForm.max_residence,
          min_residence: editForm.min_residence,
          number_of_adults: editForm.number_of_adults,
          number_of_children: editForm.number_of_children,
          box_number: editForm.box_number,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.data.message === "Profile details updated successfully") {
        toast.success("Profile details updated successfully!");
        setUserData(prev => ({
          ...prev,
          // Update user fields
          name: editForm.name,
          phone_number: editForm.phone_number,
          secondary_mobile: editForm.secondary_mobile,
          
          // Update customer fields
          customer: {
            ...prev.customer,
          whatsapp_number: editForm.whatsapp_number,
            primary_emergency_contact: editForm.primary_emergency_contact,
            secondary_emergency_contact: editForm.secondary_emergency_contact,
            occupation: editForm.occupation,
            id_type: editForm.id_type,
            id_number: editForm.id_number,
            gender: editForm.gender,
            date_of_birth: editForm.date_of_birth,
            nationality: editForm.nationality,
            plot_number: editForm.plot_number,
            block_number: editForm.block_number,
            estate_zone: editForm.estate_zone,
            street: editForm.street,
            house_number: editForm.house_number,
            max_residence: editForm.max_residence,
            min_residence: editForm.min_residence,
            number_of_adults: editForm.number_of_adults,
            number_of_children: editForm.number_of_children,
            box_number: editForm.box_number,
          },
        }));
        setEditingDetails(false);
      }
    } catch (error) {
      console.error("Update error:", error);
      if (error.response?.data?.errors) {
        Object.values(error.response.data.errors).forEach((err) => {
          toast.error(err[0]);
        });
      } else {
        toast.error(
          error.response?.data?.message ||
            "Failed to update profile. Please try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!validatePasswordForm()) {
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${config.api_path}/reset-password`,
        {
          currentPassword: passwordForm.currentPassword,
          newPassword: passwordForm.newPassword,
          confirmPassword: passwordForm.confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Password updated successfully!");
        setPasswordForm({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setPasswordErrors({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    } catch (error) {
      console.error("Password reset error:", error);
      if (error.response?.data?.errors) {
        setPasswordErrors({
          currentPassword: error.response.data.errors.currentPassword?.[0] || "",
          newPassword: error.response.data.errors.newPassword?.[0] || "",
          confirmPassword: error.response.data.errors.confirmPassword?.[0] || "",
        });
      } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to reset password. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const userImage = userData.image || DefaultImage;

  return (
    <Layout>
      <PageIntro title="User Profile" />
      <Container className="mt-4">
        <Backbtn />
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            {/* Profile Section - Image Only */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body className="d-flex flex-column align-items-center text-center">
                  <img
                    src={editingProfile ? imagePreview : userImage}
                    alt={userData.name || "User Image"}
                    className="rounded-circle mb-3"
                    width="150"
                    height="150"
                    style={{objectFit: "cover"}}
                  />
                  {editingProfile && (
                    <Form.Group controlId="formImage" className="mb-3">
                      <Form.Label>Change Profile Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Form.Group>
                  )}
                  <h3>{userData.name || "N/A"}</h3>
                  <p className="text-muted">{userData.email || "N/A"}</p>
                  <p>
                        <strong>Estate:</strong> {userData.estate?.name || 'N/A'}
                      </p>
                  <p>
                        <strong>Status:</strong> {userData.status || 'N/A'}
                      </p>
                  <div className="mt-3">
                    {editingProfile ? (
                      <div>
                        <Button
                          variant="success"
                          onClick={handleSubmitImage}
                          className="me-2"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Save Image"}
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => setEditingProfile(false)}
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => setEditingProfile(true)}
                        disabled={loading}
                      >
                        Edit Image
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Details Section */}
            <Col md={8}>
              <Card className="mb-4">
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>Profile Details</h4>
                    {!editingDetails ? (
                      <Button
                        variant="primary"
                        onClick={() => setEditingDetails(true)}
                        disabled={loading}
                      >
                        Edit Details
                      </Button>
                    ) : (
                      <div>
                        <Button
                          variant="success"
                          onClick={handleSubmitDetails}
                          className="me-2"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Save Changes"}
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => setEditingDetails(false)}
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Full Name</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="name"
                            value={editForm.name}
                            onChange={handleEditChange}
                            required
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.name || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Username</strong>
                        </Form.Label>
                        <p>
                          {(userData.name && userData.name.split(" ")[0]) || "N/A"}
                        </p>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Email</strong>
                        </Form.Label>
                        <p>{userData.email || "N/A"}</p>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Gender</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Select
                            name="gender"
                            value={editForm.gender}
                            onChange={handleEditChange}
                            required
                            disabled={loading}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </Form.Select>
                        ) : (
                          <p>{userData.customer?.gender || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Nationality</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="nationality"
                            value={editForm.nationality}
                            onChange={handleEditChange}
                            required
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.nationality || "N/A"}</p>
                        )}
                      </Form.Group>
                     
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Phone Number</strong>
                        </Form.Label>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Label><strong>Primary</strong></Form.Label>
                            {editingDetails ? (
                              <Form.Control
                                type="text"
                                name="phone_number"
                                value={editForm.phone_number}
                                onChange={handleEditChange}
                                required
                                disabled={loading}
                                placeholder="Primary phone number"
                              />
                            ) : (
                              <p>{userData.phone_number || "N/A"}</p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <Form.Label><strong>Secondary</strong></Form.Label>
                            {editingDetails ? (
                              <Form.Control
                                type="text"
                                name="secondary_mobile"
                                value={editForm.secondary_mobile || ""}
                                onChange={handleEditChange}
                                disabled={loading}
                                placeholder="Secondary phone number (optional)"
                              />
                            ) : (
                              <p>{userData.secondary_mobile || "N/A"}</p>
                            )}
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>WhatsApp Number</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="whatsapp_number"
                            value={editForm.whatsapp_number || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                            placeholder="Enter WhatsApp number with country code"
                          />
                        ) : (
                          // <p>
                          //   {userData.customer?.whatsapp_number
                          //     ? `+${userData.customer?.whatsapp_number}`
                          //     : "N/A"}
                          // </p>
                            <p>{userData.customer?.whatsapp_number || "N/A"}</p>

                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Emergency Contacts</strong>
                        </Form.Label>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Label><strong>Primary</strong></Form.Label>
                            {editingDetails ? (
                              <Form.Control
                                type="text"
                                name="primary_emergency_contact"
                                value={editForm.primary_emergency_contact}
                                onChange={handleEditChange}
                                required
                                disabled={loading}
                                placeholder="Primary emergency contact"
                              />
                            ) : (
                              <p>{userData.customer?.primary_emergency_contact || "N/A"}</p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <Form.Label><strong>Secondary</strong></Form.Label>
                            {editingDetails ? (
                              <Form.Control
                                type="text"
                                name="secondary_emergency_contact"
                                value={editForm.secondary_emergency_contact || ""}
                                onChange={handleEditChange}
                                disabled={loading}
                                placeholder="Secondary emergency contact (optional)"
                              />
                            ) : (
                              <p>{userData.customer?.secondary_emergency_contact || "N/A"}</p>
                            )}
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Occupation</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="occupation"
                            value={editForm.occupation}
                            onChange={handleEditChange}
                            required
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.occupation || "N/A"}</p>
                        )}
                      </Form.Group>

                      
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Household details Section */}
              <Card className="mb-4">
                <Card.Body>
                  <h4>Household Details</h4>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Plot Number</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="plot_number"
                            value={editForm.plot_number || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.plot_number || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Block Number</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="block_number"
                            value={editForm.block_number || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.block_number || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Estate Zone</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="estate_zone"
                            value={editForm.estate_zone || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.estate_zone || "N/A"}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Street</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="street"
                            value={editForm.street || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.street || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>House Number</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="house_number"
                            value={editForm.house_number || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.house_number || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Box Number</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="text"
                            name="box_number"
                            value={editForm.box_number || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.box_number || "N/A"}</p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Residence Details Section */}
              <Card className="mb-4">
                <Card.Body>
                  <h4>Residence Details</h4>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Max Residence</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="number"
                            name="max_residence"
                            value={editForm.max_residence || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.max_residence || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Min Residence</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="number"
                            name="min_residence"
                            value={editForm.min_residence || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.min_residence || "N/A"}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Number of Adults</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="number"
                            name="number_of_adults"
                            value={editForm.number_of_adults || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.number_of_adults || "N/A"}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <strong>Number of Children</strong>
                        </Form.Label>
                        {editingDetails ? (
                          <Form.Control
                            type="number"
                            name="number_of_children"
                            value={editForm.number_of_children || ""}
                            onChange={handleEditChange}
                            disabled={loading}
                          />
                        ) : (
                          <p>{userData.customer?.number_of_children || "N/A"}</p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Password Reset Section */}
              <Card>
                <Card.Body>
                  <h4>Reset Password</h4>
                  <br />
                  <Form onSubmit={handlePasswordReset}>
                    <Form.Group className="mb-3">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="currentPassword"
                        value={passwordForm.currentPassword}
                        onChange={handlePasswordChange}
                        required
                        disabled={loading}
                        isInvalid={!!passwordErrors.currentPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {passwordErrors.currentPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="newPassword"
                        value={passwordForm.newPassword}
                        onChange={handlePasswordChange}
                        required
                        disabled={loading}
                        isInvalid={!!passwordErrors.newPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {passwordErrors.newPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={passwordForm.confirmPassword}
                        onChange={handlePasswordChange}
                        required
                        disabled={loading}
                        isInvalid={!!passwordErrors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {passwordErrors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? "Resetting..." : "Reset Password"}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <ToastContainer />
    </Layout>
  );
};

export default UserProfile;