// src/components/Filters/Filters.tsx
import React, { useRef, useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './filter.css'

const Filters = ({ options, activeFilter, onFilterChange, style }) => {
    const filtersContainerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);

    // Check if the filters container overflows
    // useEffect(() => {
    //     const container = filtersContainerRef.current;
    //     if (!container) return;

    //     const observer = new ResizeObserver(() => {
    //         setShowScrollButtons(container.scrollWidth > container.clientWidth);
    //     });

    //     observer.observe(container);
    //     return () => observer.disconnect();
    // }, []);

    useEffect(() => {
        setShowScrollButtons(options.length > 2);
    }, [options]);

    // Scroll the filters container
    const scrollFilters = (direction) => {
        const container = filtersContainerRef.current;
        if (!container) return;

        const scrollAmount = 200; // Adjust scroll distance as needed
        container.scrollBy({
            left: direction === 'left' ? -scrollAmount : scrollAmount,
            behavior: 'smooth',
        });
    };

    return (
        <div className="filters-container">
            {/* Previous Button */}
            {showScrollButtons && (
                <button
                    className="scroll-button left"
                    onClick={() => scrollFilters('left')}
                    aria-label="Scroll left"
                >
                    <ChevronLeft size={20} />
                </button>
            )}

            {/* Filters */}
            <div ref={filtersContainerRef} className="filters">
                <Nav variant="pills" className="flex-nowrap">
                    {options.map((option) => (
                        <Nav.Item key={option.id}>
                            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                <Nav.Link
                                    active={activeFilter === option.id}
                                    onClick={() => onFilterChange(activeFilter === option.id ? null : option.id)}
                                    className="mx-1 text-nowrap"
                                    style={style} 
                                >
                                    {option.label}
                                    {option.subLabel && (
                                        <small className="d-block text-muted">
                                            {option.subLabel}
                                        </small>
                                    )}
                                </Nav.Link>
                            </motion.div>
                        </Nav.Item>
                    ))}
                </Nav>
            </div>

            {/* Next Button */}
            {showScrollButtons && (
                <button
                    className="scroll-button right"
                    onClick={() => scrollFilters('right')}
                    aria-label="Scroll right"
                >
                    <ChevronRight size={20} />
                </button>
            )} 
        </div>
    );
};

export default Filters;