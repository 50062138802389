import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";

const PropertyFilters = () => {
  const [properties, setProperties] =useState([])
  const [scrollPosition, setScrollPosition] = useState(0);
  const [agencies, setAgencies] = useState([]); // Agencies list
  const [activeType, setActiveType] = useState(0); // Default active service
  const [activeAgency, setActiveAgency] = useState(0); // Default active agency

  return (
    <div className="filters">
      <aside className="sidebar" style={{ marginTop: 0 }}>
        <h4>Filter</h4>
        <div className="filter-type">
          <label>By Status</label>
          <select name="status" type="text">
            <option value="rent">Rental</option>
            <option value="sale">On Sale</option>
            <option value="sold">Sold</option>
          </select>
        </div>
        <div className="filter-type">
          <label>By Type</label>
          <select name="type" type="text">
            <option value="0">Land</option>
            <option value="1">Property</option>
            <option value="2">Bidding</option>
          </select>
        </div>
        <div className="filter-type">
          <label>By Agency</label>
          <select name="agency" type="text">
            <option value="0">All Agencies</option>
            {agencies.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-type">
          <label>By Category</label>
          <select name="category" type="text">
            <option value="mailo">Mailo Land</option>
            <option value="kabaka">Kabaka Land</option>
          </select>
        </div>
        <div className="filter-type">
          <label>By Price</label>
          <div className="price-section">
            <input type="text" name="min-price" style={{ width: '45%' }} placeholder="Min" />
            <input type="text" name="max-price" style={{ width: '45%' }} placeholder="Max" />
          </div>
        </div>
        <div className="filter-type">
          <label>By District</label>
          <select name="district" type="text">
            <option value="kampala">Kampala</option>
            <option value="wakiso">Wakiso</option>
            <option value="entebbe">Entebbe</option>
            <option value="mukono">Mukono</option>
          </select>
        </div>
        <div className="filter-type">
          <label>By Constituency</label>
          <select name="constituency" type="text">
            <option value="kira">Kira</option>
          </select>
        </div>
        <div className="filter-type">
          <label>By Parish</label>
          <select name="parish" type="text">
            <option value="kito">Kito</option>
          </select>
        </div>
        <div className="filter-type">
          <label>By Village</label>
          <select name="village" type="text">
            <option value="kito">Kito</option>
          </select>
        </div>

        <div className='mt-4'>
          <Button variant='primary'>
            <i className="bi bi-funnel"></i> Submit filters
          </Button>
        </div>
      </aside>
      
    </div>
  );
}

export default PropertyFilters