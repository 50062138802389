import React, { useEffect, useState } from 'react';
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Backbtn from '../../components/backbtn';
import { Accordion, Button, Col, Container, Row, Table } from 'react-bootstrap';

const EstateProfile = () => {
    const [estateData, setEstateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [expandedArticles, setExpandedArticles] = useState({});
    // const { id } = useParams();
    const storedToken = localStorage.getItem('access_token');
    
    useEffect(() => {
        const fetchEstateData = async () => {
          setLoading(true);
          try {
            if (!storedToken) {
              toast.error('Authentication token is missing. Please log in again.');
              return;
            }
      
            // Fetch estate data for the logged-in user
            const response = await axios.get(`${config.api_path}/estate-profile`, {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            });
            const result = response.data;
            console.log(result);
            if(result) {
              setEstateData(result.data);
            }
            
            
            setLoading(false);
          } catch (error) {
            console.error(error.message)
            toast.error('Failed to fetch estate data. Please try again.');
            setLoading(false);
          }
        };
      
        fetchEstateData();
      }, [storedToken]);


  const toggleArticleContent = (index) => {
    setExpandedArticles((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const estateImage =
    estateData.image ||
    "https://tuweug.com/static/media/assetsTwo.d584fcf53446d78eeb9b.jpg" ||
    "https://images.unsplash.com/photo-1449824913935-59a10b8d2000?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80";

  return (
    <Layout>
      <PageIntro title="Estate Profile" />

      <Container className="mt-4">
        <Backbtn />
        <Row className="estate-profile-card">
          {loading ? (
            <Spinner />
          ) : (
            <Col>
              <div className="row image-section">
                <img
                  src={estateImage}
                  alt={estateData.name || "Estate Image"}
                  className="estate-image"
                />
                {estateData.about && (
                  <div className="about-section">
                    <h3>About</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: estateData.about }}
                    />
                  </div>
                )}
              </div>
              <div className="row details-section">
                <h3>Details</h3>
                <Table striped bordered hover>
                  <tbody>
                    {Object.keys(estateData).length > 0 ? (
                      <>
                        <tr>
                          <td className="detail-key">Subtitle</td>
                          <td className="detail-value">
                            {estateData.subtitle}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">District</td>
                          <td className="detail-value">
                            {estateData.district?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Constituency</td>
                          <td className="detail-value">
                            {estateData.constituency?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Subcounty</td>
                          <td className="detail-value">
                            {estateData.subcounty?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Parish</td>
                          <td className="detail-value">
                            {estateData.parish?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Village</td>
                          <td className="detail-value">
                            {estateData.village?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Designated Households</td>
                          <td className="detail-value">
                            {estateData.designated_households}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Actual Households</td>
                          <td className="detail-value">
                            {estateData.actual_households}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Estimated Population</td>
                          <td className="detail-value">
                            {estateData.estimated_population}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-key">Status</td>
                          <td className="detail-value">{estateData.status}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan="2">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {estateData.articles && estateData.articles.length > 0 && (
                <div className="articles-section">
                  <h3>Articles</h3>
                  <Accordion defaultActiveKey="0">
                    {estateData.articles.map((article, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                          {article.title || `Article ${index + 1}`}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: article.content,
                            }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )}
            </Col>
          )}
        </Row>
      </Container>
      <ToastContainer />
    </Layout>
  );
};

export default EstateProfile;
