
function shorten_string(word, num){
    if(word.length > num){
        return word.substring(0,num)+"..."
    }
   return word
}

function capitalizeWords(words) {
    return words
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function dateToNormal(date){
   return new Date(date).toLocaleDateString()
}

// Price formatting handler
const formatPrice = (value) => {
    // Remove any non-numeric characters except the dot
    let formattedValue = value.replace(/[^0-9.]/g, '');

    // Add commas as thousands separators
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Format integer part with commas

    // Rebuild the value
    return parts.join('.');
};

// Price formatting handler
const ReversePriceFormat = (value) => {
    // Remove any non-numeric characters except the dot
    return value.replace(/[^0-9]/g, '');
};
const escapeHtmlTags = (text) => {
    let doc = new DOMParser().parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  };

export const getFormattedDateForFilename = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
    const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero if necessary
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    return `${year}_${month}_${day}-${hours}_${minutes}`;
};

export default { escapeHtmlTags, shorten_string, formatPrice, ReversePriceFormat, dateToNormal, capitalizeWords };
