import { React, useEffect } from "react";
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import { Container } from "react-bootstrap";
import "./index.css";

const Index = () => {
  useEffect(() => {
    document.title = "Tuwe | Estates";
  });
  return (
    <Layout>
      <PageIntro title="Estates" subtitle="Welcome to Tuwe Technologies" />
      <section className="about-us">
        <Container>
          <h3>Tuwe Estates</h3>
          <p>
            Hello! Welcome to Tuwe Technologies, a brainchild of Think Tank
            Global Solutions Limited (Reg. 80010001045620). Tuwe is a visionary
            tech enterprise reshaping Uganda’s real estate landscape and
            residential estate community management experience.
          </p>
          <h3>Who We Are</h3>
          <p>
            We are your partner in navigating the complexities of property
            transactions, residential estate management, and essential services
            integration. We understand the challenges faced by property buyers,
            sellers, renters, and residential estate communities—from fraudulent
            agents to inefficient management practices—and we’ve designed
            tailored solutions to address these pain points.
          </p>
          <h3>Our Core Offerings</h3>
          <ul>
            <li>
              1. Digital Broker: Your go-to portal for property and land
              transactions, featuring a curated network of vetted agents and
              brokers. Whether you're buying, selling, or renting, Tuwe ensures
              every deal is transparent and secure.
            </li>
            <li>
              2. My Estate: An advanced Real Estate Management Information
              System (REMIS) designed to transform residential estate
              communities. From efficient fee collection to transparent
              financial reporting, My Estate enhances accountability and trust
              among residents and administrators alike.
            </li>
            <li>
              3. Integrated Services: A comprehensive directory connecting you
              to trusted service providers for essential needs like
              transportation (Estate Boda), utilities, and e-commerce, ensuring
              seamless connections for a better community experience.
            </li>
          </ul>
          <h3>Why Choose Tuwe?</h3>
          <ul>
            <li>
              Integrity at Our Core: Every listing and service provider is
              thoroughly vetted to safeguard your investments.
            </li>
            <li>
              Innovative Solutions: Our advanced tools simplify complex
              processes, saving you time and money.
            </li>
            <li>
              Commitment to Excellence: We are driven by a passion for creating
              value and delivering exceptional experiences for all stakeholders.
            </li>
          </ul>
        </Container>
      </section>
    </Layout>
  );
};

export default Index;
