import {React, useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import LocationIcon from '../../assets/svg/location.svg'
import PhoneIcon from '../../assets/icons8-phone-30 (1).png'
import bodaServices from '../../services/bodaService'
import StarRating from '../../components/stars'

import Layout from '../../layout/index'
import PageIntro from '../../components/pageIntro'

const Index = () => {

     // State for the scroll position
  const [scrollPosition, setScrollPosition] = useState(0);
  const [stages, setStages] = useState([]);
  const [boda, setBoda] = useState([]);
  const [activeStage, setActiveStage] = useState(0); // Default active service
  
  const handleStageClick = (id) => {
    if(id ==0){
      setActiveStage(0)
      setBoda(stages.filter((item)=>item.id == 1)[0].estate_bodas)
    }else{
      setActiveStage(id)
      setBoda(stages.filter((stage)=>stage.id ===id)[0].estate_bodas)
      console.log(stages.filter((stage)=>stage.id ===id)[0].estate_bodas)
    }     
  };

  const getStages =async()=>{
    try {
      const resp = await bodaServices.getBodaStages();
      setStages(resp)
      setBoda(resp.filter((item)=>item.id == 1)[0].estate_bodas)
     } catch (error) {
       console.log(error);
     }
  }

  // Amount to scroll each time
  const scrollAmount = 200;
  const scrollLeft = () => {
    setScrollPosition((prev) => prev + scrollAmount);
  };

  const scrollRight = () => {
    setScrollPosition((prev) => prev - scrollAmount);
  };

  useEffect(() => {
     getStages();
     document.title = "Tuwe | Boda Boda";
  },[])
  return (
    <Layout>
        <PageIntro title="Boda Bodas" subtitle="Your verified Boda Bodas" />
        <section className='boda-stages'>
      <Container>
        <Row className='top-boda-row' >
          <button type='button' className='btn-arrow col-md-1' 
           onClick={scrollLeft}>
            <svg
              x="0px"
              y="0px"
              className='left-arrow'
              width="20px"
              height="20px"
              viewBox="0 0 960 560"
              enableBackground="new 0 0 960 560"
            >
              <g id="Rounded_Rectangle_33_copy_4_1_">
                <path
                  fill="#fff"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </g>
            </svg>
          </button>

          <div className='stage-scroll'>
                <ul
                    className=' stage-list'
                    style={{ transform: `translateX(-${scrollPosition}px)`, transition: 'transform 0.3s ease' }}
                >
                    <li onClick={() => handleStageClick(0)}>All</li>
                    { stages.map((stage, index)=>(
                        <li
                        key={index}
                        className={activeStage === stage.id ? 'active' : ''}
                        onClick={() => handleStageClick(stage.id)}
                        >{stage.name}</li>
                    ))
                    }
                    
                </ul>
           </div>
          <button type='button' className='btn-arrow col-md-1' onClick={scrollRight}>
            <svg
              x="0px"
              y="0px"
              className='right-arrow'
              width="20px"
              height="20px"
              viewBox="0 0 960 560"
              enableBackground="new 0 0 960 560"
            >
              <g id="Rounded_Rectangle_33_copy_4_1_">
                <path
                  fill="#fff"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </g>
            </svg>
          </button>
        </Row>

        {/* Boda Cards */}
        <div className='col-md-12 bodas' style={{overflow:"hidden"}}>
         {boda.map((item)=>(
            <div className='col-md-3 card-boda' style={{height:"auto"}}>
              <div className='img-section' style={{height:'200px'}}>
                <img src={item.passport_photo?"./images/BodaPhoto.png":item.passport_photo} height="100%" width="100%" alt="" />
              </div>
              <button className='btn btn-primary small-btn'>{item.activity_status} Duty
              </button>

              <span className='title'>{item.full_name}</span>
            <div className='details-section' style={{marginTop:"10px",height:'40px',display:"flex", flexDirection:"column"}}>
            <div style={{textAlign:"center",padding:"5px"}}>Call Name: {item.call_name}</div>
            <div style={{display:"flex"}}>
              <span className='img-sec'>
                      <img src={LocationIcon} height="90%" alt="icon" />
                  </span>
                  <span className='loc-det'>123 Street, New York, USA</span> 
            </div>
                  
              </div>
              <Row className='centralise rating'>
              <StarRating rating={3.5} />
              </Row>
              <Row className='centralise'>
                <span className='call-now col-md-4'>
                  <img src={PhoneIcon} alt="phone icon" style={{height:"15px", marginTop:"5px", marginRight:"5px"}} />Call
                </span>
              </Row>
            </div>
         ))}
        </div>
      </Container>
    </section>
    </Layout>
  )
}

export default Index