import axios from 'axios';
// import Cookies from 'js-cookie';
import {config} from '../config/index'

const activitiesService = {

  
    getActivities: async () => {
        const storedToken = localStorage.getItem("access_token");
        const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
        const estate_id = localStorage.getItem("estate");
    
        if (!estate_id) {
          throw new Error("Estate ID is required to fetch activities.");
        }
    
        const response = await axios.get(`${config.api_path}/estate-activity?estate_id=${estate_id}`, { headers });
        return response.data.data;
      },
    
    getActivity: async (id) => {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
       const response = await axios.get(`${config.api_path}/estate-activity/${id}`,{
           headers
       });
       const { data } = response.data;
       return data;
    },
};

export default activitiesService;
