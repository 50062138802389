import React from 'react'
import { Button, Modal } from "react-bootstrap";
import utility from '../../helpers/index'

const Index = ({show, handleClose, title, content}) => {
  return (<Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {utility.escapeHtmlTags(content)}
            </Modal.Body>
         </Modal>
  )
}

export default Index