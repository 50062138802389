import {React, useEffect} from 'react'
import Layout from '../../layout/index'
import PageIntro from '../../components/pageIntro'
import PrivacyPolicy from '../../components/PrivacyPolicy'
import { Container } from "react-bootstrap";
import Backbtn from "../../components/backbtn";

const Index = () => {

  useEffect(() => {
    document.title = "Tuwe | Terms of Service"
})
  return (
    <Layout>
       <PageIntro title="Terms of service" subtitle="These are our terms of service" />
       <section className="about-us">
         <Container>
          <Backbtn />
          <PrivacyPolicy />
          </Container>
       </section>
    </Layout>
  )
}

export default Index