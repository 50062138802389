import axios from 'axios';
import { config } from '../config/index';



const essentialService = {
  getRotatorService: async (params = {}) => {
    const storedToken = localStorage.getItem('access_token');
    const estate = localStorage.getItem('estate');
    if (estate) {
      params.estate_id = estate;
    }
    const queryString = new URLSearchParams(params).toString();
    const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};

    const response = await axios.get(`${config.api_path}/service-categories/urgent?${queryString}`, { headers });
    const { data } = response.data;
    return data;
  },

  getEssentialService: async () => {
    const storedToken = localStorage.getItem('access_token');
    const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
    const response = await axios.get(`${config.api_path}/service-categories/essential`, {
      headers
    });
    const { data } = response.data;
    return data;
  },
  getCustomerEssentialService: async () => {
    const storedToken = localStorage.getItem('access_token');
    const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
    const response = await axios.get(`${config.api_path}/customer/service-categories/essential`, {
      headers
    });
    const { data } = response.data;
    return data;
  }
};

export default essentialService;
