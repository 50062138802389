import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import noticeBoardService from '../../services/noticeBoardService';
import Backbtn from '../../components/backbtn';

const Index = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    document.title = "Tuwe | Noticeboard";
    const getNotifications = async () => {
      try {
        const notificationResponse = await noticeBoardService.getMessages();
        console.log("notifications", notificationResponse);
        setNotifications(notificationResponse);
      } catch (error) {
        console.error("Failed to fetch data");
      }
    };
    getNotifications();
  }, []);

  const handleToggleDetails = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(/\s+/);
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <Layout>
      <PageIntro
        title="NoticeBoard"
        description="Stay updated with the latest announcements and notifications."
        subtitle="Estate Notifications"
      />
      <Container className="mt-4">
        <Backbtn />
        <Row>
          <Col>
            {notifications.length > 0 && notifications.map((notification) => (
              <Card key={notification.id} className="mb-3 shadow-sm">
                <Card.Header className="bg-light">
                  <Row className="align-items-center">
                    <Col xs={2} md={1}>
                      <Image src={notification.image} roundedCircle fluid />
                    </Col>
                    <Col xs={10} md={11}>
                      <h5>{notification.title}</h5>
                      <p className="text-muted mb-0">{notification.sub_title}</p>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <p><strong>Brief:</strong></p>
                  <div dangerouslySetInnerHTML={{
                    __html: expandedId === notification.id
                      ? notification.brief
                      : truncateText(notification.brief, 50)
                  }} />

                  {expandedId === notification.id && (
                    <div className="mt-3">
                      <p><strong>Message:</strong></p>
                      <div dangerouslySetInnerHTML={{ __html: notification.message_body }} />
                    </div>
                  )}
                  <Button
                    variant="outline-primary"
                    onClick={() => handleToggleDetails(notification.id)}
                    className="mt-2"
                  >
                    {expandedId === notification.id ? 'Hide Details' : 'View More Details'}
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Index;
