import React, { useEffect, useState } from "react";
import Layout from "../../layout/index";
import PageIntro from "../../components/pageIntro";
import { Container, Row, Col, Card, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import activitiesService from "../../services/activitiesService";
import Backbtn from "../../components/backbtn";

// Utility function to truncate text
const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : text;
};

// ActivityCard Component
const ActivityCard = ({ activity }) => {
  const truncatedDescription = truncateText(activity.description, 15);

  return (
    <Col md={4} className="mb-4">
      <Card className="h-100">
        <Card.Img
          variant="top"
          src={
            activity.image
              ? activity.image
              : "https://as2.ftcdn.net/v2/jpg/02/51/95/53/1000_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg"
          }
          className="card-img"
        />
        <Card.Body>
          <Card.Title className="text-center">
            <div dangerouslySetInnerHTML={{ __html: activity.name }} />
          </Card.Title>
          <Card.Text>
            <strong>Start:</strong> {activity.start_date} <br />
            <strong>End:</strong> {activity.end_date} <br />
            <strong>Description:</strong> <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
          </Card.Text>
          <Link to={`/activities/view/${activity.id}`}>
            <Button variant="primary">Read More</Button>
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
};

// Index Component
const Index = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Tuwe | Activities";


    

    const getActivities = async () => {
      try {
        const activitiesResponse = await activitiesService.getActivities();
        console.log("Filtered Activities:", activitiesResponse);
        setActivities(activitiesResponse);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    getActivities();
  }, []);

  return (
    <Layout>
      <PageIntro title="Activities" subtitle="Estate Activities" />
      <Container style={{ marginTop: "40px" }}>
        <Backbtn />
        {loading ? (
          <p>Loading activities...</p>
        ) : activities.length === 0 ? (
          <Alert variant="info">No activities available for your estate.</Alert>
        ) : (
          <Row>
            {activities.map((activity) => (
              <ActivityCard key={activity.id} activity={activity} />
            ))}
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default Index;
