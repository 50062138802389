import React from 'react';
import { motion } from 'framer-motion';
import { Card } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import { Bed, Bath, Home, MapPin, Verified, ArrowRight, Ruler, LandPlot } from 'lucide-react';
import "bootstrap-icons/font/bootstrap-icons.css";

import verifiedImg from '../../assets/images/verified-gold.fw.png'


const ListingCard = ({ listing }) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-UG', {
      style: 'currency',
      currency: 'UGX',
      maximumFractionDigits: 0
    }).format(price);
  };
  const srcImg = listing.type === "Land" 
              ? "https://images.unsplash.com/photo-1500382017468-9049fed747ef?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
              : "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3";

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Sharing something awesome!',
          text: 'Sharing something awesome!',
          url: 'https://tuweug.com',
        });
        console.log("Shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback for unsupported browsers
      navigator.clipboard.writeText('https://tuweug.com');
      alert("Sharing soon!");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="h-full"
    >
      <Card className="card-boda shadow-sm hover:shadow-lg transition-shadow duration-300">
        <div className="position-relative" style={{height: "240px", objectFit: "cover", backgroundColor: "rgb(131, 60, 153)"}}>
          <Card.Img
            variant="top"
            src={srcImg }
            alt={listing?.type}
            className="img-section"
            style={{height: "240px", objectFit: "cover"}}
          />
          <div className="position-absolute" style={{top: "1rem", left: "0.5rem"}}>
            {listing.isVerified && (
             <div>
                <img src={verifiedImg} alt="verified" width="25%" />
             </div>      
            )}
          </div>
          <div className="position-absolute" style={{top: "1rem", left: "0.5rem"}}>
            <span className="bg-secondary text-white px-2 py-1 rounded">
              {listing.type === "House" && listing?.listing_type === 'sale' ? 'For Sale' : 'For Rent'}
            </span>
          </div>
          <div className="position-absolute" style={{top: "1rem", right: "0.5rem"}}>
            <div className="d-flex">
              <Button variant="light" size="sm" onClick={handleShare}>
                <i className="bi bi-share"></i>
              </Button>
              <Button variant="primary" size="sm" className='ms-1'>
                <i className="bi bi-cart"></i> Add to cart
              </Button>
            </div>
          </div>
           <Card.Title className="title font-bold" style={{margin:0, bottom: 0}}>{listing.type === "Land" ? listing?.estate_name : `${listing?.owner?.name}'s House`}</Card.Title>
        </div>
        <Card.Body>

          <div className='d-flex justify-content-between align-items-center mb-1'>
            <p className="text-primary mb-1" style={{fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: "bold"}}>
              {formatPrice(listing?.price || 0)}
            </p>

            <div className="d-flex">
              <Button variant="outline-primary" size="sm">
                <i className="bi bi-hand-thumbs-up"></i> 20k
              </Button>

              <Button variant="outline-primary" size="sm" className='ms-1'>
                <i className="bi bi-hand-thumbs-down"></i> 02
              </Button>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mb-3">
            <MapPin size={14} className="text-muted" />
            <a 
              href={`https://www.google.com/maps/search/?q=${encodeURIComponent(listing?.address)}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-sm text-muted text-wrap"
              style={{ fontSize: "0.85rem", textDecoration: "none" }}
            >
              {listing?.address}
            </a>
          </div>

          {listing.type === 'House' ? (
            <div className="row gap-2 mb-3">
              <div className="col d-flex align-items-center gap-1">
                <Bed size={16} className="text-muted" />
                <span className="text-smbadge bg-info badge bg-info">{listing?.bedrooms || 1} bedrooms</span>
              </div>
              <div className="col d-flex align-items-center gap-1">
                <Bath size={16} className="text-muted"/>
                <span className="text-sm badge bg-info">{listing?.bathrooms || 1} bathrooms</span>
              </div>
              <div className="col d-flex align-items-center gap-1">
                <Home size={16} className="text-muted"/>
                <span className="text-sm badge bg-info">{listing?.propertySize || '120ft'}</span>
              </div>
            </div>
          ) : (
            <div className="row space-y-2 mb-3">
              <div className="col d-flex align-items-center gap-2">
                <Ruler size={16} className="text-muted"/>
                <span className="text-sm badge bg-info">{listing?.land_size} Decimals</span>
              </div>
              <div className="col d-flex align-items-center gap-2">
                <LandPlot size={16} className="text-muted"/>
                <span className="text-sm badge bg-primary">{listing?.category} Land</span>
              </div>
            </div>
          )}

          <div className='d-flex justify-content-between align-items-center gap-2 mb-1'>
            <p className="text-primary mb-1 bold">
              Residential
            </p>

            <p className="mb-1" style={{fontSize: "0.85rem", lineHeight: "1.75rem", fontWeight: "bold"}}>
              Discount: 10%
            </p>
          </div>

          <div className='d-flex justify-content-between align-items-center gap-2 mb-1'>
            <Button variant='outline-primary' size='sm'>
              <i className="bi bi-telephone"></i> Call
            </Button>

            <Button variant='primary' size='sm'>
              View Details <i className="bi bi-arrow-up-right"></i>
            </Button>

            <Button variant='outline-success' size='sm'>
              <i className="bi bi-whatsapp"></i> Whatsapp
            </Button>
          </div>
        </Card.Body>
      </Card>
    </motion.div>
  );
};

export default ListingCard;