import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Filter from '../common/filter';
import { RiderCard } from './riderCard';
import PaginationControls from '../common/pagination';

import bodaServices from '../../services/bodaService'
import { toast } from 'react-toastify';

const BodaStages = () => {
    
    const [activeStage, setActiveStage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [stages, setStages] = useState([]);
    const [allRiders, setAllRiders] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [totalPages, setTotalPages] = useState(1);
    const [perPage] = useState(3);


    useEffect(() => {
        const getStages = async () => {
            setLoading(true);
            try {
                const response = await bodaServices.getPaginatedBodaStages();

                // Transform stages data
                const transformedStages = response.data.map((stage) => ({
                    id: stage.id.toString(),
                    name: stage.name,
                    location: stage.location,
                }));

                // Extract and transform riders
                const transformedRiders = response.data.flatMap((stage) =>
                    stage.estate_bodas?.data.map((rider) => ({
                        id: rider.id.toString(),
                        name: rider.full_name,
                        phone: rider.telephone_number,
                        whatsapp: rider.telephone_number,
                        stage: stage.id.toString(),
                        rating: 3.5,
                        image: rider.passport_photo,
                        isOnDuty: rider.activity_status === 'On',
                    })) || []
                );

                setStages(transformedStages);
                setAllRiders(transformedRiders);

                // Set pagination metadata
                // setTotalPages(response.meta.last_page);
                // setPerPage(response.meta.per_page);
            } catch (error) {
                toast.error('Failed to fetch boda stages. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        getStages();
    }, []);

    const filterOptions = [
        { id: 'all', label: 'Featured Bodas', },
        ...stages.map((stage) => ({
            id: stage.id,
            label: stage.name,
            
        })),
    ];

    const filteredRiders = activeStage === 'all'
        ? allRiders
        : allRiders.filter(rider => rider.stage === activeStage);

    
    const indexOfLastRider = currentPage * perPage;
    const indexOfFirstRider = indexOfLastRider - perPage;
    const currentRiders = filteredRiders.slice(indexOfFirstRider, indexOfLastRider);
    const totalPages = Math.ceil(filteredRiders.length / perPage);

    

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { type: "spring", stiffness: 300, damping: 30 },
        },
    };


    return (
        <section className="py-5 bg-light">
            <Container>
                <motion.div >
                    <Row className="text-center mb-5">
                        <Col>
                            <h2 className="display-5 fw-bold mb-3">Estate Boda</h2>
                            <p className="lead text-muted">Your verified boda bodas</p>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <Filter
                                options={filterOptions}
                                activeFilter={activeStage}
                                onFilterChange={(filterId) => {
                                    setActiveStage(filterId);
                                    setCurrentPage(1);
                                }}
                            />
                        </Col>
                    </Row>

                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Row className="g-4 justify-content-center">
                                <AnimatePresence mode="wait">
                                        {currentRiders.map((rider) => (
                                        <Col key={rider.id} lg={3} md={6}>
                                            <motion.div variants={itemVariants} layout initial="hidden" animate="visible" exit="hidden">
                                                <RiderCard rider={rider} stages={stages} />
                                            </motion.div>
                                        </Col>
                                    ))}
                                </AnimatePresence>
                            </Row>

                            {totalPages > 1 && (
                                <Row className="mt-5">
                                    <Col className="d-flex justify-content-center">
                                        <PaginationControls
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            onPageChange={setCurrentPage}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </motion.div>
            </Container>
        </section>
    );
};

export default BodaStages;