import axios from 'axios';
// import Cookies from 'js-cookie';
import {config} from '../config/index'
// import jwtDecode from 'jwt-decode';

const noticeBoardService = {

    getMessages: async () => {
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
        const response = await axios.get(`${config.api_path}/estate-noticeboard`,{headers});
        const { data } = response;
        return data;
     },
     
    //  getMessageById: async (id) => {
    //      console.log(config.api_path)
    //     const response = await axios.post(`${config.api_path}/register`, { ...formData },{headers});
    //     const { data } = response.data;
    //     return data;
    //  },

};
export default noticeBoardService;
