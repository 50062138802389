import axios from 'axios';
import {config} from '../config/index'
import jwtDecode from 'jwt-decode';

const bodaService = {
    getBodaStages: async () => {
        const storedToken = localStorage.getItem('access_token');
        const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
        const response = await axios.get(`${config.api_path}/boda-stages`,{headers});
        const { data } = response.data;
        return data;
     },
     getStageBodas: async (id) => {
        const storedToken = localStorage.getItem('access_token');
        const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
        const response = await axios.get(`${config.api_path}/estate-bodas`,{headers});
        const { data } = response.data;
        return data;
     },
     getCustomersBodas: async()=>{
      const storedToken = localStorage.getItem('access_token');
      const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};
      const response = await axios.get(`${config.api_path}/customer/estate-bodas`,{headers});
      const { data } = response.data;
      return data;
     },
     getPaginatedBodaStages: async (params = {}) => {
        const storedToken = localStorage.getItem('access_token');
        const estate = localStorage.getItem('estate'); // Get estate from local storage

        const headers = storedToken ? { Authorization: `Bearer ${storedToken}` } : {};

        // Add estate_id to params if it exists
        if (estate) {
            params.estate_id = estate;
        } else {
            params.visibility = "Public";
        }

        const queryString = new URLSearchParams(params).toString();
        const response = await axios.get(`${config.api_path}/paginated-boda-stages?${queryString}`, { headers });
        return response.data; // Returns both `data` and `meta`
    },
};
export default bodaService;
