import React from 'react';
import { Table, Pagination, Badge, Button, Form, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faWhatsapp, } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import TooltipWrapper from '../common/tooltip';
import { Info } from 'lucide-react';

export const TransactionsTable = ({
  transactions,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  selectedTransactions,
  onSelectTransactions,
  filters,
  onFilterChange,
  onDownload,
  onShareWhatsApp,
  onShareEmail
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, transactions.length);
  const currentPageTransactions = transactions.slice(startIndex, endIndex);

  // Handle row selection
  const handleRowSelect = (transactionId) => {
    const newSelected = selectedTransactions.includes(transactionId)
      ? selectedTransactions.filter(id => id !== transactionId)
      : [...selectedTransactions, transactionId];
    onSelectTransactions(newSelected);
  };

  // Handle select all
  const handleSelectAll = () => {
    const allSelected = currentPageTransactions.every(t =>
      selectedTransactions.includes(t.id)
    );
    if (allSelected) {
      const newSelected = selectedTransactions.filter(id =>
        !currentPageTransactions.map(t => t.id).includes(id)
      );
      onSelectTransactions(newSelected);
    } else {
      const newSelected = [
        ...new Set([...selectedTransactions, ...currentPageTransactions.map(t => t.id)])
      ];
      onSelectTransactions(newSelected);
    }
  };


  return (
    <div>
      {/* Bulk Actions */}
      {selectedTransactions.length > 0 && (
        <div className="bulk-actions mb-3">
          <Button variant="primary" onClick={onDownload} className="me-2">
            Download Selected
          </Button>
        </div>
      )}

      {/* Filters */}
      <Row className="mb-3 g-2">
        <Col lg={4}>
          <Form.Control
            type="text"
            placeholder="Filter by PRN"
            value={filters.prn}
            onChange={(e) => onFilterChange('prn', e.target.value)}
          />
        </Col>
        <Col lg={4}>
          <Form.Control
            type="number"
            placeholder="Filter by Amount"
            value={filters.amount}
            onChange={(e) => onFilterChange('amount', e.target.value)}
          />
        </Col>
        <Col lg={4}>
          <Form.Control
            type="date"
            placeholder="Filter by Date"
            value={filters.date}
            onChange={(e) => onFilterChange('date', e.target.value)}
          />
        </Col>
      </Row>


      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={currentPageTransactions.length > 0 &&
                    currentPageTransactions.every(t => selectedTransactions.includes(t.id))}
                  onChange={handleSelectAll}
                />
              </th>
              <th>Date</th>
              <th>Description </th>
              <th>Payment Method</th>
              <th>PRN</th>
              <th>Status</th>
              <th className="text-end">Amount</th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.slice(startIndex, endIndex).map((transaction) => (
              <tr key={transaction.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedTransactions.includes(transaction.id)}
                    onChange={() => handleRowSelect(transaction.id)}
                  />
                </td>
                <td>{transaction.date}</td>
                <td>
                  <p>
                    {transaction.description}
                    <TooltipWrapper id={transaction.id} text={transaction.humanDescription}>
                      <span className="ms-2 text-primary"><Info size={16}/></span>
                    </TooltipWrapper>
                  </p>
                </td>
                <td>{transaction.paymentMethod}</td>
                <td>{transaction.prn}</td>
                <td>
                  <Badge
                    className={`status-badge ${transaction.status.toLowerCase()}`}
                  >
                    {transaction.status}
                  </Badge>
                </td>
                <td className="text-end">UGX {transaction.amount.toLocaleString()}</td>
                <td className="text-end">
                  <div className='d-flex justify-content-end align-items-center g-2'>
                    <Button
                      variant="outline-success"
                      onClick={() => onShareWhatsApp(transaction)}
                      className="btn-sm me-2"
                      title='Share Via WhatsApp'
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        size='lg'
                      />
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => onShareEmail(transaction)}
                      className="btn-sm"
                      title='Share Via Email'
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size='lg'
                      />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <small className="text-muted">
          Showing {startIndex + 1} to {endIndex} of {transactions.length} entries
        </small>
        <Pagination>
          <Pagination.Prev
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={currentPage === index + 1}
              onClick={() => onPageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};